import React from 'react';
import { ETranslationType } from '../enum/translations.enums';
import { TranslationEditorFormStatic } from '../formAdapter/TranslationEditorFormStatic';
import { TranslationEditorFormProductNameAdapter } from '../formAdapter/TranslationEditorFormProductNameAdapter';
import { TranslationEditorFormProductFieldValueAdapter } from '../formAdapter/TranslationEditorFormProductFieldValueAdapter';
import { TranslationEditorFormProductFieldLabelAdapter } from '../formAdapter/TranslationEditorFormProductFieldLabelAdapter';
import { TranslationEditorFormUserFieldLabelAdapter } from '../formAdapter/TranslationEditorFormUserFieldLabelAdapter';
import { TranslationEditorFormCategoryNameAdapter } from '../formAdapter/TranslationEditorFormCategoryNameAdapter';
import { TranslationEditorFormCategoryDescriptionAdapter } from '../formAdapter/TranslationEditorFormCategoryDescriptionAdapter';
import { TranslationEditorFormProductLabelNameAdapter } from '../formAdapter/TranslationEditorFormProductLabelNameAdapter';

interface ITranslationEditorAdapter {
    translationType: ETranslationType;
}

export const TranslationEditorAdapter: React.FC<ITranslationEditorAdapter> = ({
    translationType,
}) => {
    switch (translationType) {
        case ETranslationType.static:
            return <TranslationEditorFormStatic />;
        case ETranslationType.productName:
            return <TranslationEditorFormProductNameAdapter />;
        case ETranslationType.productFieldValue:
            return <TranslationEditorFormProductFieldValueAdapter />;
        case ETranslationType.productFieldLabel:
            return <TranslationEditorFormProductFieldLabelAdapter />;
        case ETranslationType.userFieldLabel:
            return <TranslationEditorFormUserFieldLabelAdapter />;
        case ETranslationType.categoryName:
            return <TranslationEditorFormCategoryNameAdapter />;
        case ETranslationType.categoryDescription:
            return <TranslationEditorFormCategoryDescriptionAdapter />;
        case ETranslationType.productLabelName:
            return <TranslationEditorFormProductLabelNameAdapter />;
        default:
            return null;
    }
};
