import { useEffect } from 'react';
import { useTranslationEditorState } from '../../state/TranslationEditor.state';
import { useCategoryQuery } from '../../../../frontend/hooks/category/useCategoryQuery';
import { useLocalisationState } from '../../state/localisation.state';
import { ITranslation } from '../../../../../interfaces/translation/translations.interfaces';

export const useTranslationEditorFormPopulateCategoryName = () => {
    const { translationKey, setTranslations } = useTranslationEditorState();
    const { userLanguageOptions } = useLocalisationState();
    
    const categoryID = translationKey ? (translationKey[0] as string) : '';
    const { data: category } = useCategoryQuery(categoryID);
    
    useEffect(() => {
        if (!category?.nameTranslation || !userLanguageOptions) {
            return;
        }
        
        // Get the default language from the category's nameTranslation
        const defaultLang = category.nameTranslation.languageCode;
        
        // Create translations array
        const translations: ITranslation[] = [];
        
        // Add the default language translation
        translations.push({
            languageCode: defaultLang,
            translation: category.nameTranslation.baseTranslation,
        });
        
        // Add other translations
        if (category.nameTranslation.translations) {
            category.nameTranslation.translations.forEach((translation) => {
                translations.push({
                    languageCode: translation.languageCode,
                    translation: translation.translation,
                });
            });
        }
        
        // Ensure all available languages have a translation entry (even if empty)
        userLanguageOptions.forEach((langOption) => {
            const hasTranslation = translations.some(
                (t) => t.languageCode === langOption.languageCode
            );
            
            if (!hasTranslation) {
                translations.push({
                    languageCode: langOption.languageCode,
                    translation: '',
                });
            }
        });
        
        setTranslations(translations);
    }, [category, setTranslations, translationKey, userLanguageOptions]);
};