import React, { useCallback } from 'react';
import { Button } from '../../controls/button/Button';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useLocalisationState } from '../state/localisation.state';
import { useTranslationState } from '../state/translation.state';
import { useTranslationKeySetMutation } from '../../hooks/localisation/useTranslationKeySetMutation';
import { useTranslation } from '../hooks/useTranslation';
import { ISetTranslationKeyInput } from '../../../../interfaces/translation/translations.interfaces';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

interface ITranslationSaveButtonStatic {}

export const TranslationSaveButtonStatic: React.FC<ITranslationSaveButtonStatic> = () => {
    const { translationKey, close } = useTranslationEditorState();
    const { tenantDefaultLanguage } = useLocalisationState();
    const { translations } = useTranslationState();
    const { getT } = useTranslation();
    const { mutateAsync, isPending } = useTranslationKeySetMutation();

    const handleSaveToDb = useCallback(() => {
        if (!translationKey || !tenantDefaultLanguage) return;

        const promises = translationKey.map((key) => {
            const currentKey = translations[key];
            if (!currentKey) {
                throw new Error(`Translation key "${key}" not found`);
            }
            const input: ISetTranslationKeyInput = {
                translationKey: key,
                translations: Object.entries(currentKey.translations).map((locale) => ({
                    languageCode: locale[0],
                    translation: locale[1],
                })),
                group: currentKey.group,
                context: currentKey.context,
                defaultValue: currentKey.translations[tenantDefaultLanguage.languageCode] ?? '',
                languageCode: tenantDefaultLanguage.languageCode,
            };
            return mutateAsync(input);
        });

        // Execute all mutations simultaneously
        void Promise.all(promises)
            .then(() => {
                close();
                toast.success(
                    getT('toastSuccessLocalizationsSaved') ?? 'Lokalisationen gespeichert'
                );
            })
            .catch((error: AxiosError) => {
                toast.error(
                    `${getT('toastErrorWhenSavingTranslation') ?? 'Fehler beim Speichern'}: ${
                        error.message
                    }`
                );
            });
    }, [getT, mutateAsync, tenantDefaultLanguage, translationKey, translations, close]);

    return (
        <Button
            onClick={handleSaveToDb}
            color="success"
            disabled={isPending}
            enforceWhenLocationsControls={true}
            isTranslatable={false}
        >
            Speichern
        </Button>
    );
};
