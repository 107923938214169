import { useCallback, useState } from 'react';
import { useCategoryUpdate } from '../../../admin/areas/categories/hooks/useCategoryUpdate';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useLocalisationState } from '../state/localisation.state';
import { toast } from 'react-toastify';
import { useTranslation } from '../hooks/useTranslation';
import { useQueryClient } from '@tanstack/react-query';
import { useCategoryQuery } from '../../../frontend/hooks/category/useCategoryQuery';

export const useTranslationSaveCategoryDescription = (categoryID: string) => {
    const { translations, onSave, close } = useTranslationEditorState();
    const [isPending, setIsPending] = useState(false);

    const queryClient = useQueryClient();

    // Get localization state
    const { tenantDefaultLanguage } = useLocalisationState();
    const { getT } = useTranslation();

    // Get category update mutation and category data
    const { mutateAsync: updateCategory, isPending: isMutationPending } = useCategoryUpdate();
    const { data: category } = useCategoryQuery(categoryID);

    // Ensure the JSON content is valid for storage and display
    const ensureValidTranslation = (content: string) => {
        // Verify it's JSON parsable
        try {
            JSON.parse(content);
            return content;
        } catch (e) {
            console.error('Invalid JSON in translation, attempting to fix:', e);
            // If not valid JSON, wrap it in a basic structure
            return JSON.stringify([
                {
                    type: 'paragraph',
                    children: [{ text: content }],
                },
            ]);
        }
    };

    const saveTranslationForCategoryDescription = useCallback(async () => {
        if (!translations || !tenantDefaultLanguage) {
            return;
        }

        if (isMutationPending) {
            return;
        }

        setIsPending(true);

        try {
            // Get the default language translation
            const defaultTranslation = translations.find(
                (t) => t.languageCode === tenantDefaultLanguage.languageCode
            );

            if (!defaultTranslation) {
                throw new Error('Default language translation is required');
            }

            // Get all non-default language translations
            const otherTranslations = translations.filter(
                (t) => t.languageCode !== tenantDefaultLanguage.languageCode
            );

            // Validate all translations to ensure they are valid JSON
            const validatedDefaultTranslation = ensureValidTranslation(
                defaultTranslation.translation
            );

            // Prepare the update input
            const updateInput = {
                categoryID: categoryID,
                // Add descriptionTranslation with proper structure
                descriptionTranslation: {
                    baseTranslation: validatedDefaultTranslation,
                    languageCode: tenantDefaultLanguage.languageCode,
                    // Include all non-default language translations
                    translations: otherTranslations.map((t) => ({
                        languageCode: t.languageCode,
                        translation: ensureValidTranslation(t.translation),
                    })),
                },
            };

            if (onSave) {
                // Convert the simplified translation back to the full ITranslations format for the editor
                const fullTranslation = {
                    baseTranslation: updateInput.descriptionTranslation?.baseTranslation || '',
                    languageCode: updateInput.descriptionTranslation?.languageCode || '',
                    translationKey:
                        category?.descriptionTranslation?.translationKey ||
                        `category_desc_${categoryID}`,
                    translations: updateInput.descriptionTranslation?.translations || [],
                };
                onSave(fullTranslation);
            }

            // Call the mutation with the prepared input
            await updateCategory(updateInput).then(() => {
                void queryClient.invalidateQueries({
                    queryKey: ['categories'],
                });

                void queryClient.invalidateQueries({
                    queryKey: ['category', categoryID],
                });

                toast.success(
                    getT('toastSuccessCategoryDescriptionTranslationsSaved') ??
                        'Die Übersetzungen der Kategoriebeschreibungen wurden erfolgreich gespeichert.',
                    {
                        toastId: 'category-description-translation-update-success',
                        autoClose: 2000,
                    }
                );
                close();
            });
        } catch (error) {
            console.error('Error saving category description:', error);
            toast.error(
                getT('toastErrorWhenSavingCategoryDescriptionTranslationsUpdates') ??
                    'Fehler beim Speichern von Kategoriebeschreibungsübersetzungen.',
                {
                    toastId: 'category-description-translations-update-error',
                    autoClose: 5000,
                }
            );
        } finally {
            setIsPending(false);
        }
    }, [
        translations,
        tenantDefaultLanguage,
        isMutationPending,
        onSave,
        categoryID,
        updateCategory,
        queryClient,
        getT,
        close,
        category?.descriptionTranslation?.translationKey,
    ]);

    return {
        saveTranslationForCategoryDescription,
        isPending: isPending || isMutationPending,
    };
};
