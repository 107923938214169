import { useApi } from '../api/useApi';
import { useQuery } from '@tanstack/react-query';
import { IProductDB } from '../../../../interfaces/product/IProduct';
import { mapProductFromDBToFE } from '../../../../interfaces/product/maps';
import { PRODUCT_QUERY } from './queries/product.query';

export const useProductQuery = (id?: string, prefix = 'product') => {
    const { postGql } = useApi();
    return useQuery({
        staleTime: 60000,
        enabled: !!id && id !== 'new',
        queryKey: [prefix, id],
        queryFn: async () => {
            const { product } = await postGql<{ product: IProductDB }>('product', PRODUCT_QUERY, {
                id,
            });

            return mapProductFromDBToFE(product, { mapVariants: true, mapParent: true });
        },
    });
};
