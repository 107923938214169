import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { toast } from 'react-toastify';
import { useApi } from '../../../general/hooks/api/useApi';
import { IProductDB } from '../../../../interfaces/product/IProduct';
import { mapProductFromDBToFE } from '../../../../interfaces/product/maps';
import { ITranslationInput } from '../../../general/Translations/types/translation.interfaces';

export interface IFieldSetValueAdminInput {
    productID: string; // This will be mapped to entityID in the actual API call
    fieldName: string;
    fieldValue: string;
    visible?: boolean;
    fieldValueTranslation?: ITranslationInput;
}

export const useProductFieldSetValueMutation = (productId: string) => {
    const { postGql } = useApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (input: IFieldSetValueAdminInput) => {
            // Map our input to the expected GraphQL input format
            const apiInput = {
                entityID: input.productID,
                fieldName: input.fieldName,
                fieldValue: input.fieldValue,
                visible: input.visible,
                fieldValueTranslation: input.fieldValueTranslation,
            };

            const response = await postGql<{
                productFieldSetValue: IProductDB;
            }>(
                'productFieldSetValue',
                gql`
                    mutation ($input: FieldSetValueAdminInput!) {
                        productFieldSetValue(input: $input) {
                            id
                            name
                            code
                            type
                            orderable
                            published
                            hidePlaceControls
                            created
                            accessRoleIDs
                            defaultOutputPipeline
                            isFavourite
                            archived
                            projectID
                            thumbnailConfig
                            hideItemName
                            parentProductID
                            staticLinkURL
                            deleted
                            descriptionFromParent

                            variantProductIDs

                            tenantIDs
                            defaultTemplateFile
                            nameTranslation {
                                baseTranslation
                                languageCode
                                translationKey
                                group
                                tags
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                            fields {
                                group
                                name
                                value
                                valueTranslation {
                                    baseTranslation
                                    languageCode
                                    translationKey
                                    group
                                    tags
                                    translations {
                                        languageCode
                                        translation
                                    }
                                }
                                config
                                defaultValue
                                visible
                            }
                            categoryIDs
                            categories {
                                id
                                name
                                description
                            }
                            stock {
                                allowNegative
                                amount
                                warnAt
                            }
                            images {
                                id
                                path
                                tag
                                width
                                height
                            }
                            description
                            descriptionTranslation {
                                baseTranslation
                                languageCode
                                translationKey
                                group
                                tags
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                            priceTableID
                            quantityOptions {
                                mode
                                min
                                max
                                scales
                                stepSize
                            }
                        }
                    }
                `,
                { input: apiInput }
            );

            return mapProductFromDBToFE(response.productFieldSetValue);
        },
        onSuccess: (_, variables) => {
            const key = ['product', variables.productID, 'products', 'product-admin'];
            void queryClient.invalidateQueries({ queryKey: key });

            void queryClient.invalidateQueries({
                queryKey: ['product', productId],
            });
        },
        onError: () => {
            toast.error('Failed to update product field');
        },
    });
};
