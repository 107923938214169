// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO This is a prototype for a serializer/deserializer for HTML to Slate and vice versa.
 * Made with AI, must be reworked on a later point; it does work so far
 */

import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, Slate, withReact } from 'slate-react';
import { createEditor, Descendant, Editor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Label } from '../../typo/label/Label';
import {
    TRichTextDataObject,
    useRichTextDataTypeAnalyzer,
} from './helper/useRichTextDataTypeAnalyzer';
import { SlateButtonRow, toggleMark, TSlateButtons } from './elements/SlateButtonRow';
import { Bars3BottomLeftIcon } from '@heroicons/react/16/solid';
import { TranslationDirection } from '../../Translations/elements/TranslationDirection/TranslationDirection';
import { ERichTextStyles, RichTextDisplay } from './RichTextDisplay';
import { PageStyleElement } from '../../../frontend/areas/CMSPage/slateElementMapper/pageStyleElements';
import { TitleStyleElement } from '../../../frontend/areas/CMSPage/slateElementMapper/titleStyleElements';
import editorStyles from './SlateEditor.module.scss';
import { EAccess } from '../../../../interfaces/role/IAccess';
import { TitleInvertStyleElements } from '../../../frontend/areas/CMSPage/slateElementMapper/titleInvertStyleElements';
import { useLocalisationState } from '../../Translations/state/localisation.state';
import { sanitizeSlateInitialValue, slateConstructIsEmpty } from './helper/slateConstructIsEmpty';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
};

type LeafProps = {
    attributes: any;
    children: ReactNode;
    leaf: any;
};

// eslint-disable-next-line react/no-multi-comp
const Leaf: React.FC<LeafProps> = ({ attributes, children, leaf }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access

    if (leaf.code) {
        children = <code>{children}</code>;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (leaf.italic) {
        children = <em>{children}</em>;
    }
    if (leaf.underline) {
        children = <u>{children}</u>;
    }
    return <span {...attributes}>{children}</span>;
};

export interface RichTextProps {
    initialValue: Descendant[];
    onChange: (value: Descendant[]) => void;
    label?: string;
    testId?: string;
    required?: boolean;
    buttons?: TSlateButtons;
    onSave?: (output: TRichTextDataObject | undefined) => void;
    allowEdit?: boolean;
    styles?: ERichTextStyles;
    placeholder?: string;
    editAccess?: EAccess[];
    richTextClasses?: string;
    defaultRenderNode?: 'heading-one' | 'heading-two' | 'paragraph' | null;
    baseTranslationExists?: boolean;
    sourceLanguage?: string;
    targetLanguage?: string;
    aiTranslationLabel?: string;
    editorMinHeight?: number | null;
}

// eslint-disable-next-line react/no-multi-comp
export const SlateEditorInPlace = (props: RichTextProps) => {
    const {
        initialValue,
        onSave,
        onChange,
        allowEdit,
        required,
        testId,
        label,
        buttons,
        styles,
        placeholder,
        richTextClasses = '',
        defaultRenderNode = 'paragraph',
        baseTranslationExists = true,
        sourceLanguage = 'de-DE', // Default fallback values
        targetLanguage,
        aiTranslationLabel,
        editorMinHeight = null,
    } = props;

    const { userCurrentLanguage } = useLocalisationState();

    const [outputForSave, setOutputForSave] = useState<TRichTextDataObject | null>(null);

    // Sanitize initialValue to ensure it's never an empty array which causes Slate to break
    const sanitizedInitialValue = useMemo(
        () => sanitizeSlateInitialValue(initialValue, defaultRenderNode || 'paragraph'),
        [initialValue, defaultRenderNode]
    );

    /**
     * SLATEJS EDITOR STYLES HERE :)
     */
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const renderElement = useCallback(
        (propsLocal) => {
            switch (styles) {
                case ERichTextStyles.cmsPageStyles:
                    return <PageStyleElement {...propsLocal} />;
                case ERichTextStyles.cmsTitleStyles:
                    return <TitleStyleElement {...propsLocal} />;
                case ERichTextStyles.cmsTitleInvertedStyles:
                    return <TitleInvertStyleElements {...propsLocal} />;
                default:
                    return <PageStyleElement {...propsLocal} />;
            }
        },
        [styles]
    );

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

    const editor = useMemo(() => {
        const newEditor = withHistory(withReact(createEditor()));

        // Only render default styles, when it's singular and explicitly needed, like with `heading-one` only elements
        if (defaultRenderNode && defaultRenderNode !== 'paragraph') {
            // Normalize to enforce all blocks are `heading-one`
            const { normalizeNode } = newEditor;
            newEditor.normalizeNode = ([node, path]) => {
                if (Editor.isBlock(newEditor, node) && node.type !== defaultRenderNode) {
                    Transforms.setNodes(newEditor, { type: defaultRenderNode }, { at: path });
                }
                normalizeNode([node, path]);
            };
        }

        return newEditor;
    }, [defaultRenderNode]);

    const { convertAsRichTextDataType, parseSlateToHTML } = useRichTextDataTypeAnalyzer();

    const [showRichText, setShowRichText] = useState(false);

    // Only close the editor when language changes (not when showRichText changes)
    // We use a ref to track previous language to avoid closing when component first mounts
    const prevLanguageRef = React.useRef(userCurrentLanguage?.languageCode);
    useEffect(() => {
        // Only run this effect if language changes and not on initial mount
        if (
            prevLanguageRef.current !== userCurrentLanguage?.languageCode &&
            prevLanguageRef.current !== undefined
        ) {
            if (showRichText) {
                setShowRichText(false);
            }
        }
        prevLanguageRef.current = userCurrentLanguage?.languageCode;
    }, [userCurrentLanguage?.languageCode, showRichText]);

    const hoverStyles = useMemo(() => {
        if (showRichText) return 'hover:ring-2 hover:ring-cloudbarPrimary/50';
        return '';
    }, [showRichText]);

    const isEmpty = useMemo(() => {
        return slateConstructIsEmpty(sanitizedInitialValue);
    }, [sanitizedInitialValue]);

    // Check if there's a translation available for the current language
    const hasTranslationForCurrentLanguage = useMemo(() => {
        return (
            userCurrentLanguage &&
            targetLanguage === userCurrentLanguage.languageCode &&
            !userCurrentLanguage.default &&
            Array.isArray(sanitizedInitialValue) &&
            sanitizedInitialValue.length > 0 &&
            JSON.stringify(sanitizedInitialValue) !== '[]' &&
            JSON.stringify(sanitizedInitialValue[0]) !==
                '{"type":"paragraph","children":[{"text":""}]}' &&
            JSON.stringify(sanitizedInitialValue[0]) !==
                '{"type":"heading-one","children":[{"text":""}]}' &&
            JSON.stringify(sanitizedInitialValue[0]) !==
                '{"type":"heading-one","children":[{"text":"","type":"heading-one"}]}'
        );
    }, [sanitizedInitialValue, targetLanguage, userCurrentLanguage]);

    // Determine if we're in translation mode
    const isTranslating = useMemo(() => {
        // Show translation indicator in non-default language when baseTranslation exists
        // and either the content is empty or we're in a title element
        return (
            userCurrentLanguage &&
            !userCurrentLanguage.default &&
            baseTranslationExists &&
            (isEmpty || (defaultRenderNode === 'heading-one' && !hasTranslationForCurrentLanguage))
        );
    }, [
        userCurrentLanguage,
        baseTranslationExists,
        isEmpty,
        defaultRenderNode,
        hasTranslationForCurrentLanguage,
    ]);

    const RichTextDisplayPart = useMemo(() => {
        return (
            <RichTextDisplay
                content={parseSlateToHTML(sanitizedInitialValue, styles)}
                styles={styles}
                testId={testId}
                richTextClasses={richTextClasses}
            />
        );
    }, [sanitizedInitialValue, parseSlateToHTML, richTextClasses, styles, testId]);

    const minHeightEditorField: React.CSSProperties = useMemo(() => {
        return {
            minHeight: '22.5px',
        };
    }, []);

    return (
        <div
            className={`relative flex w-full flex-col gap-0 transition-all ${hoverStyles}`}
            style={{
                minHeight: editorMinHeight ? `${editorMinHeight}px` : undefined,
            }}
        >
            {label && <Label required={required}>{label}</Label>}

            {!showRichText && !isEmpty && !allowEdit && RichTextDisplayPart}

            {!showRichText && allowEdit && (
                <button
                    className={`text-left bg-cloudbar-light/10 h-full`}
                    onClick={() => {
                        setShowRichText(true);
                    }}
                    style={{ ...minHeightEditorField }}
                    data-test-id="slate-editor-button"
                >
                    {/* Show content if not empty */}
                    {!isEmpty && (
                        <div className="relative h-full">
                            {RichTextDisplayPart}
                            {/* Show translation indicator as an overlay when translating */}
                            {isTranslating && (
                                <div className="absolute top-0 right-0 rounded-bl p-1 bg-cloudbar-light/20">
                                    <TranslationDirection
                                        fromLanguage={sourceLanguage}
                                        toLanguage={
                                            targetLanguage ||
                                            userCurrentLanguage?.languageCode ||
                                            'en-GB'
                                        }
                                        iconClassName="h-2 w-2 text-cloudbar"
                                        className="h-5"
                                        label={aiTranslationLabel ?? undefined}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {/* Show placeholder if empty and placeholder exists */}
                    {isEmpty && placeholder && (
                        <span
                            data-test-id={`${testId ?? ''}-placeholder` ?? undefined}
                            className="flex w-full items-center justify-center text-xl font-bold text-cloudbar"
                        >
                            {isTranslating && (
                                <TranslationDirection
                                    fromLanguage={sourceLanguage}
                                    toLanguage={
                                        targetLanguage ||
                                        userCurrentLanguage?.languageCode ||
                                        'en-GB'
                                    }
                                    iconClassName="h-3 w-3 text-cloudbar"
                                    showAiTranslating={true}
                                    className="text-cloudbar"
                                    label={aiTranslationLabel ?? undefined}
                                    layout="horizontal"
                                />
                            )}

                            {!isTranslating && placeholder}
                        </span>
                    )}

                    {/* Show empty indicator or translation UI */}
                    {isEmpty && !placeholder && (
                        <div className="flex items-center justify-center py-2">
                            {isTranslating ? (
                                <TranslationDirection
                                    fromLanguage={sourceLanguage}
                                    toLanguage={
                                        targetLanguage ||
                                        userCurrentLanguage?.languageCode ||
                                        'en-GB'
                                    }
                                    iconClassName="h-3 w-3 text-cloudbar"
                                    showAiTranslating={true}
                                    className="text-cloudbar"
                                    label={aiTranslationLabel ?? undefined}
                                />
                            ) : (
                                <Bars3BottomLeftIcon className="h-10 transition-all text-cloudbar group-hover:text-white" />
                            )}
                        </div>
                    )}
                </button>
            )}
            {/* EDITOR - EDITING PART / RICH-TEXT */}
            {showRichText && (
                <div className={`SlateEditorWrapper ${styles} ${richTextClasses}`}>
                    <Slate
                        editor={editor}
                        data-test-id={`${testId ?? ''}-wrapper` ?? undefined}
                        initialValue={sanitizedInitialValue}
                        onChange={(value) => {
                            const converted = convertAsRichTextDataType(value, 'slatejs');
                            onChange(converted);
                            setOutputForSave(value);
                        }}
                    >
                        <SlateButtonRow
                            testId={testId}
                            editor={editor}
                            buttons={buttons}
                            show={showRichText}
                            showSaveButton={showRichText}
                            onSave={() => {
                                setShowRichText(false);
                                onSave({
                                    type: 'slatejs',
                                    content: JSON.stringify(outputForSave),
                                });
                            }}
                            close={() => {
                                setShowRichText(false);
                            }}
                        />

                        <Editable
                            data-test-id={testId ?? undefined}
                            className={`${editorStyles.SlateEditor as string} SlateEditor`}
                            style={{ ...minHeightEditorField }}
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            spellCheck
                            autoFocus={true}
                            onFocus={() => {
                                // Reset selection to beginning when focusing to avoid DOM selection errors
                                try {
                                    Transforms.select(editor, {
                                        anchor: { path: [0, 0], offset: 0 },
                                        focus: { path: [0, 0], offset: 0 },
                                    });
                                } catch (e) {
                                    // Ignore any errors with selection
                                }
                            }}
                            onKeyDown={(event) => {
                                for (const hotkey in HOTKEYS) {
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                    if (isHotkey(hotkey, event as any)) {
                                        event.preventDefault();
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                        const mark = HOTKEYS[hotkey];
                                        toggleMark(editor, mark);
                                    }
                                }
                            }}
                        />
                    </Slate>
                </div>
            )}
        </div>
    );
};

export default SlateEditorInPlace;
