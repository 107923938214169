import { useCallback, useState } from 'react';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useLocalisationState } from '../state/localisation.state';
import { toast } from 'react-toastify';
import { useTranslation } from '../hooks/useTranslation';
import { useCategoryQuery } from '../../../frontend/hooks/category/useCategoryQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useCategoryUpdate } from '../../../admin/areas/categories/hooks/useCategoryUpdate';
import { ICategoryUpdateInput } from '../../../../interfaces/category/api/ICategoryUpdateInput';

export const useTranslationSaveCategoryName = (categoryID: string) => {
    const { translations, onSave, close } = useTranslationEditorState();
    const [isPending, setIsPending] = useState(false);

    const queryClient = useQueryClient();

    // Get localization state
    const { tenantDefaultLanguage } = useLocalisationState();
    const { getT } = useTranslation();

    // Get category update mutation and category data
    const { mutateAsync: updateCategory, isPending: isMutationPending } = useCategoryUpdate();
    const { data: category } = useCategoryQuery(categoryID);

    const saveTranslationForCategoryName = useCallback(async () => {
        if (!translations || !tenantDefaultLanguage) {
            return;
        }

        if (isMutationPending) {
            return;
        }

        setIsPending(true);

        try {
            // Get the default language translation
            const defaultTranslation = translations.find(
                (t) => t.languageCode === tenantDefaultLanguage.languageCode
            );

            if (!defaultTranslation) {
                throw new Error('Default language translation is required');
            }

            // Get all non-default language translations
            const otherTranslations = translations.filter(
                (t) => t.languageCode !== tenantDefaultLanguage.languageCode
            );

            // Prepare the update input
            const updateInput: ICategoryUpdateInput = {
                categoryID: categoryID,
                // Include other required category data
                name: defaultTranslation.translation,
                // Add nameTranslation with proper structure
                nameTranslation: {
                    baseTranslation: defaultTranslation.translation,
                    languageCode: tenantDefaultLanguage.languageCode,
                    // Do not include translationKey as it's not expected in the TranslationInput type
                    // Include all non-default language translations
                    translations: otherTranslations.map((t) => ({
                        languageCode: t.languageCode,
                        translation: t.translation,
                    })),
                },
            };

            if (onSave) {
                // Convert the simplified translation back to the full ITranslations format for the editor
                const fullTranslation = {
                    baseTranslation: updateInput.nameTranslation?.baseTranslation || '',
                    languageCode: updateInput.nameTranslation?.languageCode || '',
                    translationKey: category?.nameTranslation?.translationKey || `category_${categoryID}`,
                    translations: updateInput.nameTranslation?.translations || []
                };
                onSave(fullTranslation);
            }

            // Call the mutation with the prepared input
            await updateCategory(updateInput).then(() => {
                void queryClient.invalidateQueries({
                    queryKey: ['categories'],
                });

                void queryClient.invalidateQueries({
                    queryKey: ['category', categoryID],
                });

                toast.success(
                    getT('toastSuccessCategoryNameTranslationsSaved') ??
                        'Die Übersetzungen der Kategorienamen wurden erfolgreich gespeichert.',
                    {
                        toastId: 'category-name-translation-update-success',
                        autoClose: 2000,
                    }
                );
                close();
            });
        } catch (error) {
            console.error(error);
            toast.error(
                getT('toastErrorWhenSavingCategoryNameTranslationsUpdates') ??
                    'Fehler beim Speichern von Kategorienamenübersetzungen.',
                {
                    toastId: 'category-name-translations-update-error',
                    autoClose: 5000,
                }
            );
        } finally {
            setIsPending(false);
        }
    }, [
        translations,
        tenantDefaultLanguage,
        isMutationPending,
        onSave,
        categoryID,
        updateCategory,
        queryClient,
        getT,
        close,
        category?.nameTranslation?.translationKey
    ]);

    return {
        saveTranslationForCategoryName,
        isPending: isPending || isMutationPending,
    };
};