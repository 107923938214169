import React from 'react';
import { ETranslationEntity } from '../types/translationApply.types';
import { TranslationFieldStaticArray } from '../elements/TranslationFieldStaticArray';
import { useTranslationEditorState } from '../state/TranslationEditor.state';

interface ITranslationEditorFormStatic {}

export const TranslationEditorFormStatic: React.FC<ITranslationEditorFormStatic> = () => {
    const { translationKey, translationEntity } = useTranslationEditorState();

    return (
        <>
            {translationEntity === ETranslationEntity.text && (
                <TranslationFieldStaticArray
                    index={0}
                    label={translationKey?.[0] ?? ''}
                    translationKey={translationKey}
                />
            )}
            {translationEntity === ETranslationEntity.select && (
                <div className="relative flex w-full flex-col gap-6">
                    {translationKey?.map((item, index) => {
                        return (
                            <TranslationFieldStaticArray
                                key={`item${index}`}
                                index={index}
                                label={translationKey?.[index] ?? ''}
                                translationKey={translationKey}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};
