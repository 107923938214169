import { useCallback, useState } from 'react';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useLocalisationState } from '../state/localisation.state';
import { toast } from 'react-toastify';
import { useTranslation } from '../hooks/useTranslation';
import { useQueryClient } from '@tanstack/react-query';
import { useProductLabelUpdateMutation } from '../../../admin/hooks/product/useProductLabelUpdateMutation';

export const useTranslationSaveProductLabelName = (productID: string, labelID: string) => {
    const { translations, onSave, close } = useTranslationEditorState();
    const [isPending, setIsPending] = useState(false);

    const queryClient = useQueryClient();

    // Get localization state
    const { tenantDefaultLanguage } = useLocalisationState();
    const { getT } = useTranslation();

    // Get product label update mutation
    const { mutateAsync: updateProductLabel, isPending: isMutationPending } = useProductLabelUpdateMutation();

    const saveTranslationForProductLabelName = useCallback(async () => {
        if (!translations || !tenantDefaultLanguage) {
            return;
        }

        if (isMutationPending) {
            return;
        }

        setIsPending(true);

        try {
            // Get the default language translation
            const defaultTranslation = translations.find(
                (t) => t.languageCode === tenantDefaultLanguage.languageCode
            );

            if (!defaultTranslation) {
                throw new Error('Default language translation is required');
            }

            // Get all non-default language translations
            const otherTranslations = translations.filter(
                (t) => t.languageCode !== tenantDefaultLanguage.languageCode
            );

            // Prepare the update input
            const updateInput = {
                productID,
                labelID,
                nameTranslation: {
                    baseTranslation: defaultTranslation.translation,
                    languageCode: tenantDefaultLanguage.languageCode,
                    // The translationKey is not expected by the TranslationInput type
                    translations: otherTranslations.map((t) => ({
                        languageCode: t.languageCode,
                        translation: t.translation,
                    })),
                },
            };

            if (onSave) {
                // Make sure we don't include any fields not expected by the TranslationInput type
                const saveInput = {
                    baseTranslation: updateInput.nameTranslation.baseTranslation,
                    languageCode: updateInput.nameTranslation.languageCode,
                    translations: updateInput.nameTranslation.translations
                };
                onSave(saveInput);
            }

            // Call the mutation with the prepared input
            await updateProductLabel(updateInput).then(() => {
                void queryClient.invalidateQueries({
                    queryKey: ['product', productID],
                });

                toast.success(
                    getT('toastSuccessProductLabelNameTranslationsSaved') ??
                        'Die Übersetzungen des Produktlabelnamens wurden erfolgreich gespeichert.',
                    {
                        toastId: 'product-label-name-translation-update-success',
                        autoClose: 2000,
                    }
                );
                close();
            });
        } catch (error) {
            console.error(error);
            toast.error(
                getT('toastErrorWhenSavingProductLabelNameTranslationsUpdates') ??
                    'Fehler beim Speichern von Produktlabelnamenübersetzungen.',
                {
                    toastId: 'product-label-name-translations-update-error',
                    autoClose: 5000,
                }
            );
        } finally {
            setIsPending(false);
        }
    }, [
        translations,
        tenantDefaultLanguage,
        isMutationPending,
        onSave,
        productID,
        labelID,
        updateProductLabel,
        queryClient,
        getT,
        close,
    ]);

    return {
        saveTranslationForProductLabelName,
        isPending: isPending || isMutationPending,
    };
};