import React, { useCallback } from 'react';
import { Button } from '../../controls/button/Button';
import { useTranslationSaveUserFieldLabel } from '../adapters/useTranslationSaveUserFieldLabel';
import { useTranslation } from '../hooks/useTranslation';

export const TranslationSaveButtonUserFieldLabel: React.FC = () => {
    const { saveUserFieldLabelTranslation, isPending } = useTranslationSaveUserFieldLabel();
    const { getT } = useTranslation();

    const handleSaveToDb = useCallback(() => {
        void saveUserFieldLabelTranslation();
    }, [saveUserFieldLabelTranslation]);

    return (
        <Button
            onClick={handleSaveToDb}
            color="success"
            disabled={isPending}
            enforceWhenLocationsControls={true}
            iconSpin={isPending}
            isTranslatable={false}
        >
            {getT('save') ?? 'Speichern'}
        </Button>
    );
};
