/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { useThemeStyles } from '../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import React from 'react';

export const PageStyleElement = ({ attributes, children, element }) => {
    const { applyStyles } = useThemeStyles();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'heading-one':
            return (
                <h1
                    {...attributes}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.primary.DEFAULT',
                        }),
                    }}
                >
                    {children}
                </h1>
            );
        case 'heading-two':
            return (
                <h2
                    {...attributes}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.primary.DEFAULT',
                        }),
                    }}
                >
                    {children}
                </h2>
            );
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        // case 'table':
        //     return <table {...attributes}>{children}</table>;
        // case 'table-body':
        //     return <tbody {...attributes}>{children}</tbody>;
        // case 'table-row':
        //     return <tr {...attributes}>{children}</tr>;
        // case 'table-cell':
        //     return <td {...attributes}>{children}</td>;
        // case 'table-header':
        //     return <th {...attributes}>{children}</th>;
        default:
            // Assuming 'paragraph' as the default type
            return <p {...attributes}>{children}</p>;
    }
};
