import { useCallback, useState } from 'react';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useProductUpdateMutation } from '../../../admin/areas/product/hooks/useProductUpdateMutation';
import { IProductUpdateInput } from '../../../../interfaces/product/api/IProductUpdateInput';
import { useLocalisationState } from '../state/localisation.state';
import { toast } from 'react-toastify';
import { useTranslation } from '../hooks/useTranslation';
import { useProductQuery } from '../../hooks/product/useProductQuery';
import { useQueryClient } from '@tanstack/react-query';

export const useTranslationSaveProductName = (productID: string) => {
    const { translations, onSave, close } = useTranslationEditorState();
    const [isPending, setIsPending] = useState(false);

    const queryClient = useQueryClient();

    // Get localization state
    const { tenantDefaultLanguage } = useLocalisationState();
    const { getT } = useTranslation();

    // Get product update mutation and product data
    const { mutateAsync: updateProduct, isPending: isMutationPending } = useProductUpdateMutation();
    const { data: product } = useProductQuery(productID);

    const saveTranslationForProductName = useCallback(async () => {
        if (!translations || !tenantDefaultLanguage) {
            return;
        }

        if (isMutationPending) {
            return;
        }

        setIsPending(true);

        try {
            // Get the default language translation
            const defaultTranslation = translations.find(
                (t) => t.languageCode === tenantDefaultLanguage.languageCode
            );

            if (!defaultTranslation) {
                throw new Error('Default language translation is required');
            }

            // Get all non-default language translations
            const otherTranslations = translations.filter(
                (t) => t.languageCode !== tenantDefaultLanguage.languageCode
            );

            // Prepare the update input
            const updateInput: IProductUpdateInput = {
                productID: productID,
                // Include other required product data
                name: product?.name || '',
                code: product?.code || '',
                type: product?.type || 'product',
                // Set up name translation with proper structure
                nameTranslation: {
                    baseTranslation: defaultTranslation.translation,
                    languageCode: tenantDefaultLanguage.languageCode,
                    // Include all non-default language translations
                    translations: otherTranslations.map((t) => ({
                        languageCode: t.languageCode,
                        translation: t.translation,
                    })),
                },
            };

            if (onSave) {
                onSave(updateInput?.nameTranslation ?? undefined);
            }

            // Call the mutation with the prepared input
            await updateProduct(updateInput).then(() => {
                void queryClient.invalidateQueries({
                    queryKey: ['product', productID],
                });

                void queryClient.invalidateQueries({
                    queryKey: ['products'],
                });

                toast.success(
                    getT('toastSuccessProductNameTranslationsSaved') ??
                        'Die Übersetzungen der Produktnamen wurden erfolgreich gespeichert.',
                    {
                        toastId: 'admin-product-name-translation-update-success',
                        autoClose: 2000,
                    }
                );
                close();
            });
        } catch (error) {
            console.error(error);
            toast.error(
                getT('toastErrorWhenSavingProductNameTranslationsUpdates') ??
                    'Fehler beim Speichern von Produktnamenübersetzungen.',
                {
                    toastId: 'admin-product-name-translations-update-error',
                    autoClose: 5000,
                }
            );
        } finally {
            setIsPending(false);
        }
    }, [
        translations,
        tenantDefaultLanguage,
        isMutationPending,
        onSave,
        productID,
        product?.name,
        product?.code,
        product?.type,
        updateProduct,
        queryClient,
        getT,
        close,
    ]);

    return {
        saveTranslationForProductName,
        isPending: isPending || isMutationPending,
    };
};
