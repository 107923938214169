import { useApi } from '../../../general/hooks/api/useApi';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { IGenericField, IGenericFieldConfig } from '../../../../interfaces/user/IGenericField';
import { EProductFieldGroup, IProductField } from '../../../../interfaces/product/IProduct';

export const mapFieldFromBEToFE = (field: IGenericField): IProductField => {
    const config = field.config as string;

    // Return IProductField instead of IUserField
    const configUsed = JSON.parse(config ? config : '') as IGenericFieldConfig;

    return {
        ...field,
        name: field.fieldName,
        type: configUsed?.type,
        config: configUsed,
        defaultValue: field.defaultValue ?? '',
        group: field.group as EProductFieldGroup,
    } as IProductField;
};

export const useProductGenericFieldsQuery = () => {
    const { postGql } = useApi();

    return useQuery({
        queryKey: ['productGenericFields'],
        staleTime: 10000,
        queryFn: async () => {
            const { productGenericFields } = await postGql<{
                productGenericFields: IGenericField[];
            }>(
                'productGenericFields',
                gql`
                    query {
                        productGenericFields {
                            fieldName
                            group
                            config
                            defaultValue
                            tenantID
                            order
                        }
                    }
                `,
                { tenantID: null }
            );

            return productGenericFields.map((field) => mapFieldFromBEToFE(field));
        },
    });
};
