import { useCallback, useState } from 'react';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useLocalisationState } from '../state/localisation.state';
import { toast } from 'react-toastify';
import { useTranslation } from '../hooks/useTranslation';
import { useUserUpdateFieldMutation } from '../../hooks/user/useUserUpdateField';
import { useUserGenericFieldsQuery } from '../../hooks/user/useUserGenericFieldsQuery';

export const useTranslationSaveUserFieldLabel = () => {
    const { translations, onSave, close, translationKey } = useTranslationEditorState();
    // const queryClient = useQueryClient();

    const fieldName = translationKey?.[0] ?? '';

    const [isPending, setIsPending] = useState(false);

    const { tenantDefaultLanguage } = useLocalisationState();
    const { getT } = useTranslation();

    // Get the current user field to access its config
    const { data: userFields } = useUserGenericFieldsQuery({
        staleTime: 900000,
        enabled: true,
    });

    const { mutateAsync: updateUserField, isPending: isFieldMutationPending } =
        useUserUpdateFieldMutation();

    const saveUserFieldLabelTranslation = useCallback(async () => {
        if (!translations || !tenantDefaultLanguage || !fieldName) {
            return;
        }

        if (isFieldMutationPending) {
            return;
        }

        setIsPending(true);

        try {
            // Find the current user field to get its existing config
            const userField = userFields?.find((field) => field.fieldName === fieldName);
            if (!userField) {
                throw new Error('User field not found');
            }

            // Get the default language translation
            const defaultTranslation = translations.find(
                (t) => t.languageCode === tenantDefaultLanguage.languageCode
            );

            if (!defaultTranslation) {
                throw new Error('Default language translation is required');
            }

            // Get all non-default language translations
            const otherTranslations = translations.filter(
                (t) => t.languageCode !== tenantDefaultLanguage.languageCode
            );

            // Create labelTranslation object
            const labelTranslation = {
                baseTranslation: defaultTranslation.translation,
                languageCode: tenantDefaultLanguage.languageCode,
                translations: otherTranslations.map((t) => ({
                    languageCode: t.languageCode,
                    translation: t.translation,
                })),
            };

            if (onSave) {
                onSave(labelTranslation);
            }

            // Parse the existing config to preserve all properties
            let configObj: Record<string, unknown> = { type: 'text' };
            if (userField.config) {
                try {
                    if (typeof userField.config === 'string') {
                        configObj = JSON.parse(userField.config) as Record<string, unknown>;
                    } else {
                        configObj = userField.config as unknown as Record<string, unknown>;
                    }
                } catch (e) {
                    console.error('Error parsing existing config, using default', e);
                }
            }

            // Update the config with the new labelTranslation while preserving other properties
            const updatedConfig = {
                ...configObj,
                labelTranslation,
                // Also set the label to match the default translation for consistency
                label: defaultTranslation.translation,
            };

            // Update the user field with the new config
            await updateUserField({
                fieldName: fieldName,
                config: JSON.stringify(updatedConfig),
                // Don't modify other properties
                defaultValue: undefined,
                group: undefined,
            }).then(() => {
                // TODO This cretes problems with reloading the page. We need to fix this
                // void queryClient.invalidateQueries({ queryKey: ['userGenericFields'] });

                // TODO TEMPORARY
                window.location.reload();
            });

            // Success message after the update
            toast.success(
                getT('toastSuccessUserFieldLabelTranslationsSaved') ??
                    'Die Übersetzungen der Benutzerfeldbeschriftung wurden erfolgreich gespeichert.',
                {
                    toastId: 'user-field-label-translation-update-success',
                    autoClose: 2000,
                }
            );
            close();
        } catch (error) {
            console.error(error);
            toast.error(
                getT('toastErrorWhenSavingUserFieldLabelTranslationsUpdates') ??
                    'Fehler beim Speichern von Benutzerfeldbeschriftungsübersetzungen.',
                {
                    toastId: 'user-field-label-translations-update-error',
                    autoClose: 5000,
                }
            );
        } finally {
            setIsPending(false);
        }
    }, [
        translations,
        tenantDefaultLanguage,
        fieldName,
        isFieldMutationPending,
        onSave,
        updateUserField,
        getT,
        close,
        userFields,
    ]);

    return {
        saveUserFieldLabelTranslation,
        isPending: isPending || isFieldMutationPending,
    };
};
