import React, { forwardRef, useCallback, useMemo } from 'react';
import { TextField } from '../../controls/texfield/TextField';
import { FlagSymbol } from '../../elements/FlagSymbol/FlagSymbol';
import { ArrowPathIcon } from '@heroicons/react/16/solid';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useTranslation } from '../hooks/useTranslation';
import { useTranslationState } from '../state/translation.state';
import { useTranslationFieldsEmptyDetector } from '../hooks/useTranslationFieldsEmptyDetector';
import { useAutomaticTranslation } from '../hooks/useAutomaticTranslation';

export interface ITranslationField {
    locale: string;
    label?: string;
    required?: boolean;
    index: number;
}

type ITranslationFieldProps = Omit<ITranslationField, 'ref'> &
    React.RefAttributes<HTMLInputElement>;

export const TranslationFieldStatic: React.FC<ITranslationFieldProps> = forwardRef<
    HTMLInputElement,
    ITranslationFieldProps
>((props, ref) => {
    const { locale, label, required = false, index = 0 } = props;

    const { translationKey } = useTranslationEditorState();
    const { automaticTranslationActive } = useAutomaticTranslation();
    const { translations } = useTranslationState();

    const { getT, setT } = useTranslation();

    const { isTranslationKeyEmpty } = useTranslationFieldsEmptyDetector();

    const currentKey = useMemo(() => {
        return translationKey?.[index] ?? null;
    }, [index, translationKey]);

    const handleChange = useCallback(
        (value: string) => {
            if (!translationKey) return;
            if (!currentKey) return;

            setT(currentKey, locale, value);
        },
        [currentKey, locale, setT, translationKey]
    );

    const currentLocalIsEmpty: boolean = useMemo(() => {
        if (!translationKey) return true;
        if (!translations) return true;

        const testLocal = getT(translationKey[index] ?? '', locale);

        if (testLocal === null) {
            return true;
        }

        return testLocal.trim().length === 0;
    }, [getT, index, locale, translationKey, translations]);

    const showLoadingIcon: boolean = useMemo(() => {
        if (!automaticTranslationActive) return false;
        if (isTranslationKeyEmpty(translationKey, index)) {
            return false;
        }
        return currentLocalIsEmpty;
    }, [
        automaticTranslationActive,
        isTranslationKeyEmpty,
        translationKey,
        index,
        currentLocalIsEmpty,
    ]);

    return (
        <div className="relative flex flex-col gap-1">
            {currentKey && (
                <div className="relative flex flex-row items-center gap-1">
                    {translationKey && (
                        <TextField
                            ref={ref}
                            value={getT(currentKey, locale) ?? undefined}
                            label={label}
                            iconStart={<FlagSymbol countryCode={locale} />}
                            required={required}
                            onChange={handleChange}
                            placeholder={
                                showLoadingIcon
                                    ? (getT('translationEditorQueuePlaceholder') as string)
                                    : undefined
                            }
                            iconEnd={
                                showLoadingIcon ? (
                                    <ArrowPathIcon className="h-5 animate-spin" />
                                ) : undefined
                            }
                        />
                    )}
                </div>
            )}
        </div>
    );
});

TranslationFieldStatic.displayName = 'TranslationField';
