import React, { useMemo } from 'react';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { Typography } from '../../typo/Typography/Typography';
import { useLocalisationState } from '../state/localisation.state';
import { useLanguageName } from '../../hooks/localisation/useLanguageName';
import { FlagSymbol } from '../../elements/FlagSymbol/FlagSymbol';
import { TextField } from '../../controls/texfield/TextField';
import { useTranslationEditorFormPopulateCategoryName } from './hooks/useTranslationEditorFormPopulateCategoryName';

interface ITranslationEditorFormCategoryNameAdapter {}

export const TranslationEditorFormCategoryNameAdapter: React.FC<
    ITranslationEditorFormCategoryNameAdapter
> = () => {
    const { translationKey, translations, setTranslationValueByKey } = useTranslationEditorState();

    const { userLanguageOptions, getCurrentLanguage } = useLocalisationState();
    const { getLanguageName } = useLanguageName(getCurrentLanguage().languageCode);

    const defaultTranslation = useMemo(() => {
        const defaultLanguage = userLanguageOptions?.find((el) => el.default);
        const defaultT = translations?.find(
            (el) => el.languageCode === defaultLanguage?.languageCode
        );
        return defaultT?.translation ?? '';
    }, [translations, userLanguageOptions]);

    useTranslationEditorFormPopulateCategoryName();

    return (
        <div className="relative flex w-full flex-col gap-4 border-b border-dotted pb-8 border-cloudbarPrimary">
            <Typography variant={'h5'}>{defaultTranslation ?? translationKey ?? ''}</Typography>

            {userLanguageOptions &&
                userLanguageOptions.length > 0 &&
                userLanguageOptions.map((el) => {
                    const name = `${getLanguageName(el.languageCode)}${
                        el.default ? ' - STANDARDSPRACHE' : ''
                    }`;

                    const currentValue = translations?.find(
                        (t) => t.languageCode === el.languageCode
                    );

                    return (
                        <TextField
                            key={`translation-${el.languageCode}`}
                            value={currentValue?.translation ?? ''}
                            onChange={(value) => {
                                setTranslationValueByKey(el.languageCode, value);
                            }}
                            label={name}
                            iconStart={<FlagSymbol countryCode={el.languageCode} />}
                            required={el.default}
                        />
                    );
                })}
        </div>
    );
};
