import React from 'react';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { Typography } from '../../typo/Typography/Typography';
import { useLocalisationState } from '../state/localisation.state';
import { useLanguageName } from '../../hooks/localisation/useLanguageName';
import { FlagSymbol } from '../../elements/FlagSymbol/FlagSymbol';
import { TextField } from '../../controls/texfield/TextField';
import { useTranslationEditorFormPopulateProductLabelName } from './hooks/useTranslationEditorFormPopulateProductLabelName';

interface ITranslationEditorFormProductLabelNameAdapter {}

export const TranslationEditorFormProductLabelNameAdapter: React.FC<
    ITranslationEditorFormProductLabelNameAdapter
> = () => {
    const { translations, setTranslationValueByKey } = useTranslationEditorState();
    const { userLanguageOptions, getCurrentLanguage } = useLocalisationState();
    const { getLanguageName } = useLanguageName(getCurrentLanguage().languageCode);

    // Use the hook to populate product label name data
    useTranslationEditorFormPopulateProductLabelName();

    return (
        <div className="relative flex w-full flex-col gap-4 border-b border-dotted pb-8 border-cloudbarPrimary">
            <Typography variant="h5">Label Name</Typography>

            {userLanguageOptions &&
                userLanguageOptions.length > 0 &&
                userLanguageOptions.map((el) => {
                    const name = `${getLanguageName(el.languageCode)}${
                        el.default ? ' - STANDARDSPRACHE' : ''
                    }`;

                    const currentTranslation = translations?.find(
                        (t) => t.languageCode === el.languageCode
                    );

                    return (
                        <div key={`translation-${el.languageCode}`} className="mb-4">
                            <div className="mb-2 flex items-center gap-2">
                                <FlagSymbol countryCode={el.languageCode} />
                                <Typography variant="label" className="font-medium">
                                    {name}
                                </Typography>
                            </div>
                            <TextField
                                type="text"
                                value={currentTranslation?.translation || ''}
                                onChange={(e) => {
                                    setTranslationValueByKey(el.languageCode, e);
                                }}
                                required={el.default}
                            />
                        </div>
                    );
                })}
        </div>
    );
};
