import { useApi } from '../api/useApi';
import { useMutation } from '@tanstack/react-query';
import { IGenericFieldBE } from '../../../../interfaces/user/IGenericFieldBE';
import { gql } from 'graphql-request';

export interface IFieldEntityUpdateInput {
    fieldName: string;
    config?: string;
    defaultValue?: string;
    group?: string;
    order?: number;
}

export const useUserUpdateFieldMutation = () => {
    const { postGql } = useApi();

    return useMutation({
        mutationFn: async (input: IFieldEntityUpdateInput) => {
            const { userFieldUpdate: data } = await postGql<{ userFieldUpdate: IGenericFieldBE[] }>(
                'userFieldUpdate',
                gql`
                    mutation ($input: FieldGenericUpdateInput!) {
                        userFieldUpdate(input: $input) {
                            fieldName
                            group
                            config
                            defaultValue
                            tenantID
                            order
                            translations {
                                languageCode
                                baseTranslation
                                translationKey
                                group
                                context
                                tags
                            }
                        }
                    }
                `,
                { input }
            );

            return data;
        },
    });
};
