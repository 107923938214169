import { useEffect } from 'react';
import { useTranslationEditorState } from '../../state/TranslationEditor.state';
import { useCategoryQuery } from '../../../../frontend/hooks/category/useCategoryQuery';
import { useLocalisationState } from '../../state/localisation.state';
import { createMinimalSlateNode } from '../../../controls/RichText/helper/slateConstructIsEmpty';

// Helper function to ensure we have valid Slate JSON for descriptions
const ensureValidSlateJSON = (description: string): string => {
    try {
        // Check if the description is already a valid JSON string
        JSON.parse(description);
        return description;
    } catch (e) {
        // If it's not valid JSON, create a minimal slate node
        const slateContent = createMinimalSlateNode();
        // Return as a JSON string
        return JSON.stringify(slateContent);
    }
};

export const useTranslationEditorFormPopulateCategoryDescription = () => {
    const { translationKey, setTranslations } = useTranslationEditorState();
    const { tenantDefaultLanguage } = useLocalisationState();

    // Get the category ID from the translation key
    const categoryID = translationKey?.[0] as string;

    // Fetch the category data
    const { data: category, isLoading } = useCategoryQuery(categoryID);

    useEffect(() => {
        if (!isLoading && category && tenantDefaultLanguage) {
            // Extract the description translations from the category
            const descriptionTranslation = category.descriptionTranslation;

            if (descriptionTranslation) {
                // Map the translations to the format expected by the form, ensuring valid Slate JSON
                const translationsArray = descriptionTranslation.translations.map(
                    (translation) => ({
                        languageCode: translation.languageCode,
                        translation: ensureValidSlateJSON(translation.translation),
                    })
                );

                // If the default language is not in the translations, add it
                const defaultLanguageExists = translationsArray.some(
                    (translation) => translation.languageCode === tenantDefaultLanguage.languageCode
                );

                if (!defaultLanguageExists && descriptionTranslation.baseTranslation) {
                    translationsArray.push({
                        languageCode: tenantDefaultLanguage.languageCode,
                        translation: ensureValidSlateJSON(descriptionTranslation.baseTranslation),
                    });
                }

                // Set the translations in the form state
                setTranslations(translationsArray);
            } else {
                // If no translations exist yet, create one for the default language with a valid Slate structure
                const defaultTranslation = category.description || '';
                setTranslations([
                    {
                        languageCode: tenantDefaultLanguage.languageCode,
                        translation: ensureValidSlateJSON(defaultTranslation),
                    },
                ]);
            }
        }
    }, [category, isLoading, setTranslations, tenantDefaultLanguage, translationKey]);
};
