import { useEffect } from 'react';
import { useTranslationEditorState } from '../../state/TranslationEditor.state';
import { ITranslation } from '../../../../../interfaces/translation/translations.interfaces';
import { useLocalisationState } from '../../state/localisation.state';
import { useProductQuery } from '../../../hooks/product/useProductQuery';

export const useTranslationEditorFormPopulateProductLabelName = () => {
    const { translationKey, setTranslations } = useTranslationEditorState();
    const { userLanguageOptions } = useLocalisationState();

    // The key structure should be: [productID, labelID]
    const productID = translationKey?.[0] as string;
    const labelID = translationKey?.[1] as string;

    // Fetch the product data
    const { data: product, isLoading } = useProductQuery(productID);

    useEffect(() => {
        if (!userLanguageOptions || isLoading || !product) {
            return;
        }

        // Find the specific label from the product
        const productLabel = product.labels?.find((label) => label.labelID === labelID);

        if (!productLabel || !productLabel.nameTranslation) {
            // Initialize translations for all available languages with empty values
            // For default language, use the label name as default value
            const emptyTranslations: ITranslation[] = userLanguageOptions.map((language) => ({
                languageCode: language.languageCode,
                translation: language.default ? productLabel?.name || '' : '',
            }));
            setTranslations(emptyTranslations);
            return;
        }

        // Get nameTranslation from the found label
        const nameTranslation = productLabel.nameTranslation;

        // Map translations from the API format to the editor format
        const mappedTranslations: ITranslation[] = userLanguageOptions.map((language) => {
            // If this is the default language (matches the translation's languageCode),
            // use the baseTranslation
            if (language.languageCode === nameTranslation.languageCode) {
                return {
                    languageCode: language.languageCode,
                    translation: nameTranslation.baseTranslation || '',
                };
            }

            // For other languages, find the translation in the translations array
            const existingTranslation = nameTranslation.translations?.find(
                (t) => t.languageCode === language.languageCode
            );

            // Return the translation or an empty one
            return {
                languageCode: language.languageCode,
                translation: existingTranslation?.translation || '',
            };
        });

        setTranslations(mappedTranslations);
    }, [product, labelID, userLanguageOptions, setTranslations, isLoading]);
};
