import { useCallback, useEffect, useState } from 'react';
import { useTranslationEditorState } from '../../state/TranslationEditor.state';
import { ETranslationType } from '../../enum/translations.enums';
import { useProductQuery } from '../../../hooks/product/useProductQuery';
import { useLocalisationState } from '../../state/localisation.state';
import { ITranslation } from '../../../../../interfaces/translation/translations.interfaces';

export const useTranslationEditorFormPopulateProductName = () => {
    const { translationType, translationKey, setTranslations } = useTranslationEditorState();
    const { userLanguageOptions, tenantDefaultLanguage } = useLocalisationState();
    const { data: product, isPending } = useProductQuery(translationKey?.[0] ?? '');

    const [init, setInit] = useState<null | string>(null);

    const populate = useCallback(() => {
        if (!product || !userLanguageOptions || !userLanguageOptions.length || !tenantDefaultLanguage) {
            return;
        }

        // Create a map of existing translations by language code for quick lookup
        const translationsMap = new Map<string, string>();

        // First, add all existing explicit translations from the translations array
        if (product.nameTranslation?.translations) {
            product.nameTranslation.translations.forEach(translation => {
                translationsMap.set(translation.languageCode, translation.translation);
            });
        }

        // Then, ensure the base translation is included for the default language
        if (product.nameTranslation?.baseTranslation && tenantDefaultLanguage.languageCode) {
            translationsMap.set(
                tenantDefaultLanguage.languageCode,
                product.nameTranslation.baseTranslation
            );
        }

        // Create a full set of translations for all tenant languages
        const fullTranslations: ITranslation[] = userLanguageOptions.map(language => {
            // If we have an existing translation for this language, use it
            // Otherwise, create an empty translation
            return {
                languageCode: language.languageCode,
                translation: translationsMap.get(language.languageCode) || ''
            };
        });

        // Set the translations in the state
        setTranslations(fullTranslations);
    }, [product, userLanguageOptions, tenantDefaultLanguage, setTranslations]);

    useEffect(() => {
        if (init || init === translationKey?.[0] || isPending) return undefined;

        if (translationType === ETranslationType.productName) {
            setInit(translationKey?.[0] ?? '');
            populate();
        }
    }, [init, isPending, populate, translationKey, translationType]);
};
