import React, { createRef, useMemo } from 'react';
import { TranslationFieldStatic } from './TranslationFieldStatic';
import { useLanguageName } from '../../hooks/localisation/useLanguageName';
import { useLocalisationState } from '../state/localisation.state';
import { Typography } from '../../typo/Typography/Typography';
import { useTranslationFieldsEmptyDetector } from '../hooks/useTranslationFieldsEmptyDetector';
import { useTranslationState } from '../state/translation.state';
import { Infobox } from '../../display/infobox/Infobox';

export interface ITranslationFieldStaticArray {
    index: number;
    label: string;
    translationKey?: string[] | undefined;
}

export const TranslationFieldStaticArray: React.FC<ITranslationFieldStaticArray> = (props) => {
    const { index = 0, label = '', translationKey } = props;

    const { userLanguageOptions, getCurrentLanguage } = useLocalisationState();

    const { getLanguageName } = useLanguageName(getCurrentLanguage().languageCode);

    const { isTranslationKeyEmpty } = useTranslationFieldsEmptyDetector();

    const { lastUpdated } = useTranslationState();

    const isEmpty = useMemo(() => {
        if (lastUpdated === null || lastUpdated === undefined) return true;
        if (!translationKey) return true;

        return isTranslationKeyEmpty(translationKey, index);
    }, [index, isTranslationKeyEmpty, translationKey, lastUpdated]);

    const translationFieldRefs = useMemo(() => {
        return userLanguageOptions
            ? userLanguageOptions.map(() => createRef<HTMLInputElement>())
            : [];
    }, [userLanguageOptions]);

    return (
        <div className="relative flex w-full flex-col gap-4 border-b border-dotted pb-8 border-cloudbarPrimary">
            <Typography variant={'h5'}>{label}</Typography>

            {isEmpty && (
                <Infobox translationKey={'translationDefaultTranslationMandatory'} small={true} />
            )}
            {userLanguageOptions &&
                userLanguageOptions.length > 0 &&
                userLanguageOptions.map((el) => {
                    const name = `${getLanguageName(el.languageCode)}${
                        el.default ? ' - STANDARDSPRACHE' : ''
                    }`;

                    return (
                        <TranslationFieldStatic
                            key={`translation-${el.languageCode}`}
                            index={index}
                            ref={translationFieldRefs[index]}
                            locale={el.languageCode}
                            label={name}
                            required={el.default}
                        />
                    );
                })}
        </div>
    );
};
