import { useCallback, useState } from 'react';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useLocalisationState } from '../state/localisation.state';
import { toast } from 'react-toastify';
import { useTranslation } from '../hooks/useTranslation';
import { useQueryClient } from '@tanstack/react-query';
import { EProductFieldGroup } from '../../../../interfaces/product/IProduct';
import { useProductUpdateFieldMutation } from '../../../admin/hooks/productField/useProductUpdateFieldMutation';

export const useTranslationSaveProductFieldLabel = () => {
    const { translations, onSave, close, translationKey } = useTranslationEditorState();
    const queryClient = useQueryClient();

    const fieldName = translationKey?.[1] ?? '';

    const [isPending, setIsPending] = useState(false);

    const { tenantDefaultLanguage } = useLocalisationState();
    const { getT } = useTranslation();

    const { mutateAsync: updateProductField, isPending: isFieldMutationPending } =
        useProductUpdateFieldMutation();

    const saveProductFieldLabelTranslation = useCallback(async () => {
        if (!translations || !tenantDefaultLanguage || !fieldName) {
            return;
        }

        if (isFieldMutationPending) {
            return;
        }

        setIsPending(true);

        try {
            // Get the default language translation
            const defaultTranslation = translations.find(
                (t) => t.languageCode === tenantDefaultLanguage.languageCode
            );

            if (!defaultTranslation) {
                throw new Error('Default language translation is required');
            }

            // Get all non-default language translations
            const otherTranslations = translations.filter(
                (t) => t.languageCode !== tenantDefaultLanguage.languageCode
            );

            // Create translation object
            const labelTranslation = {
                baseTranslation: defaultTranslation.translation,
                languageCode: tenantDefaultLanguage.languageCode,
                translations: otherTranslations.map((t) => ({
                    languageCode: t.languageCode,
                    translation: t.translation,
                })),
            };

            if (onSave) {
                onSave(labelTranslation);
            }

            // Update the generic field template
            await updateProductField({
                fieldName: fieldName,
                labelTranslation: labelTranslation,
                // Don't modify other properties
                order: undefined,
                defaultValue: undefined,
                group: EProductFieldGroup.INFO,
                config: undefined,
            }).then(() => {
                // Invalidate the generic fields query
                void queryClient.invalidateQueries({ queryKey: ['productGenericFields'] });
            });

            // Success message after the update
            toast.success(
                getT('toastSuccessProductFieldLabelTranslationsSaved') ??
                    'Die Übersetzungen der Produktfeldbezeichnung wurden erfolgreich gespeichert.',
                {
                    toastId: 'admin-product-field-label-translation-update-success',
                    autoClose: 2000,
                }
            );
            close();
        } catch (error) {
            console.error(error);
            toast.error(
                getT('toastErrorWhenSavingProductFieldLabelTranslationsUpdates') ??
                    'Fehler beim Speichern von Produktfeldbezeichnungsübersetzungen.',
                {
                    toastId: 'admin-product-field-label-translations-update-error',
                    autoClose: 5000,
                }
            );
        } finally {
            setIsPending(false);
        }
    }, [
        translations,
        tenantDefaultLanguage,
        fieldName,
        isFieldMutationPending,
        onSave,
        updateProductField,
        queryClient,
        getT,
        close,
    ]);

    return {
        saveProductFieldLabelTranslation,
        isPending: isPending || isFieldMutationPending,
    };
};
