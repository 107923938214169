import { useApi } from '../api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import {
    ISetTranslationKeyInput,
    ITranslations,
} from '../../../../interfaces/translation/translations.interfaces';

export const useTranslationKeySetMutation = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (input: ISetTranslationKeyInput) => {
            const { translationKeySet: data } = await postGql<{ translationKeySet: ITranslations }>(
                'translationKeySet',
                gql`
                    mutation ($input: SetTranslationKeyInput!) {
                        translationKeySet(input: $input) {
                            translationKey
                            baseTranslation
                            languageCode
                            translations {
                                languageCode
                                translation
                            }
                        }
                    }
                `,
                { input }
            );

            return data;
        },
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['translationsByTenant'],
            });
        },
    });
};
