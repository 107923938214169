import { useMemo } from 'react';
import { useTenantSelf } from '../../hooks/tenant/useTenantSelf';

export const useAutomaticTranslation = () => {
    const { tenant } = useTenantSelf();

    const automaticTranslationActive: boolean = useMemo(() => {
        return tenant?.deeplTranslationActive ?? false;
    }, [tenant?.deeplTranslationActive]);

    return { automaticTranslationActive };
};
