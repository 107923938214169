import React, { useMemo } from 'react';
import { FlagSymbol } from '../../../elements/FlagSymbol/FlagSymbol';
import { ArrowPathIcon, ArrowRightIcon } from '@heroicons/react/16/solid';
import { useTenantSelf } from '../../../hooks/tenant/useTenantSelf';
import { getCountryCodeFromLocale } from '../../helper/getCountryCodeFromLocale';

export interface ITranslationDirectionProps {
    fromLanguage: string;
    toLanguage: string;
    className?: string;
    iconClassName?: string;
    flagClassName?: string;
    showAiTranslating?: boolean;
    aiTranslatingTextClassName?: string;
    label?: string;
    layout?: 'horizontal' | 'vertical';
}

/**
 * Component that displays translation direction with source and target language flags
 * Can optionally show an AI translation indicator
 */
export const TranslationDirection: React.FC<ITranslationDirectionProps> = ({
    fromLanguage,
    toLanguage,
    className = '',
    iconClassName = 'h-4 w-4 text-cloudbar',
    flagClassName = '',
    showAiTranslating = false,
    aiTranslatingTextClassName = 'text-sm',
    label = 'AI is translating ...',
    layout = 'vertical',
}) => {
    const flexClasses = useMemo(() => {
        if (layout === 'vertical') {
            return 'flex flex-col items-center text-cloudbar gap-3 min-h-10 py-2';
        }

        return 'flex flex-row items-center text-cloudbar gap-3 min-h-10 py-2';
    }, [layout]);

    const { tenant } = useTenantSelf();

    const show: boolean = useMemo(() => {
        return tenant?.deeplTranslationActive ?? false;
    }, [tenant?.deeplTranslationActive]);

    // Extract country codes from language/locale codes (e.g. "en-GB" -> "gb")
    const getCountryCode = (locale: string): string => {
        if (!show) return '';
        return getCountryCodeFromLocale(locale);
    };

    const fromCountryCode = getCountryCode(fromLanguage);
    const toCountryCode = getCountryCode(toLanguage);

    // Basic flag direction component
    const FlagDirection = (
        <div className="flex flex-row items-center gap-1">
            <div className={`flex items-center justify-center h-5 ${flagClassName}`}>
                <FlagSymbol countryCode={fromCountryCode} />
            </div>
            <ArrowRightIcon className={iconClassName} aria-hidden="true" />
            <div className={`flex items-center justify-center h-5 ${flagClassName}`}>
                <FlagSymbol countryCode={toCountryCode} />
            </div>
        </div>
    );

    // If we don't need to show AI translating indicator, just return the flag direction
    if (!showAiTranslating) {
        return <div className={`flex flex-row items-center ${className}`}>{FlagDirection}</div>;
    }

    // Return the full AI translating indicator with flags
    return (
        <>
            {show && (
                <div
                    className={`${flexClasses} items-center text-cloudbar gap-3 min-h-10 py-2 ${className}`}
                >
                    <div className="flex flex-row items-center gap-3">{FlagDirection}</div>
                    <div className="flex flex-row items-center gap-2">
                        <ArrowPathIcon className="h-3 w-3 animate-spin transition-all" />
                        <span className={`animate-pulse ${aiTranslatingTextClassName}`}>
                            {label}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default TranslationDirection;
