import { ICategoryDB } from '../category/ICategory';
import { IMedia, IProductProperties } from '../cart/productInCart';
import { EProduct } from '../../enums/product.enums';
import { IPriceTable } from './IPriceTable';
import { IGenericFieldConfig } from '../user/IGenericField';
import { IEntityField } from '../item/IEntityField';
import { IStock } from '../../components/admin/hooks/stock/interfaces/productStock.interfaces';
import { IDynamicThumbnail } from '../../components/general/elements/DynamicThumbnail/interfaces/dynamicThumbnail.interfaces';
import { ITranslations } from '../translation/translations.interfaces';
import { IProductLabel } from './IProductLabel';

export type IProductQuantityOptions = {
    mode?: string | null;
    min?: number | null;
    max?: number | null;
    scales?: number[] | null;
    stepSize?: number | null;
};

export enum EProductFieldGroup {
    META = 'metadata',
    INFO = 'info',
    // DESCRIPTION = 'description',
}

export interface IProductField extends IEntityField {
    group: EProductFieldGroup;
    config: IGenericFieldConfig;
    order?: number;
}

export interface IProductDB {
    id: string;
    code: string;
    type: 'static' | 'PAGEFLEX' | 'printformer';
    name: string;
    created: string;
    published?: string;

    labels?: IProductLabel[];

    fields: IProductField[];
    orderable?: boolean;
    hidePlaceControls?: boolean;

    staticLinkURL?: string;

    translationID?: string;
    priceTableID?: string;
    tenantIDs?: string[];
    isFavourite?: boolean;
    priceTable?: IPriceTable; // todo - create PriceTable type or IPriceTable
    accessRoleIDs: string[];
    archived?: string;
    projectID?: string;
    defaultBitmapJob?: string;
    defaultPreviewJob?: string;
    defaultFinalJob?: string;
    deliveryPackageGroupID?: string;
    discountPercent?: number;
    // fields: ProductField[]; // todo - create ProductField type or IProductField
    categories?: ICategoryDB[];
    categoryIDs: string[];

    deleted?: string | null;

    quantityOptions: IProductQuantityOptions;

    images: IMedia[];

    stock?: IStock;

    description: string | null;

    thumbnailConfig?: string;
    nameTranslation?: ITranslations;
    descriptionTranslation?: ITranslations;
    hideItemName?: boolean;
    defaultTemplateFile?: string;
    defaultOutputPipeline?: string | null;

    variantProductIDs?: string[];
    variants?: IProductDB[];

    parentProductID?: string;
    parent?: IProduct;
    descriptionFromParent?: boolean;
}

export type TProductDescription = {
    type: 'html' | 'draftjs';
    content: string;
};

export interface IProduct {
    id: string;
    code: string;
    type: EProduct;
    name: string;
    created: string;
    published: string | null;
    publish?: boolean;
    orderable?: boolean;
    hidePlaceControls?: boolean;

    staticLinkURL?: string;

    fields: IProductField[];

    accessRoleIDs: string[];
    categoryIDs: string[];
    priceTableID?: string;

    labels?: IProductLabel[];

    tenantIDs?: string[];

    quantityOptions: IProductQuantityOptions;

    categories?: ICategoryDB[];

    archived?: string | null;

    stock?: IStock;

    description: null | string;

    deleted?: string | null;

    // TODO clean that interface...
    // from IProduct interface from Client App
    // id: string;
    testId?: string;
    numberOfPages?: number;
    printable: boolean;
    itemName: string;
    imagesAll: IMedia[];
    images: IMedia[];
    thumbnails: IMedia[];
    newItem?: boolean;
    properties?: IProductProperties[];
    isResponsive?: boolean;
    itemDescription?: string;
    priceTable?: IPriceTable;
    isFavourite?: boolean;
    thumbnailConfig?: IDynamicThumbnail;
    nameTranslation?: ITranslations;
    descriptionTranslation?: ITranslations;
    hideItemName?: boolean;
    defaultTemplateFile?: string;

    defaultOutputPipeline?: string | null;

    variants?: IProduct[];
    variantProductIDs?: string[];

    parentProductID?: string;
    parent?: IProduct;
    descriptionFromParent?: boolean;
}
