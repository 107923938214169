import React, { useCallback } from 'react';
import { Button } from '../../controls/button/Button';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useTranslationSaveProductName } from '../adapters/useTranslationSaveProductName';

interface ITranslationSaveButtonProductName {}

export const TranslationSaveButtonProductName: React.FC<ITranslationSaveButtonProductName> = () => {
    const { translationKey } = useTranslationEditorState();
    const productID = translationKey ? (translationKey[0] as string) : '';

    const { saveTranslationForProductName, isPending } = useTranslationSaveProductName(productID);

    const handleSaveToDb = useCallback(() => {
        void saveTranslationForProductName();
    }, [saveTranslationForProductName]);

    return (
        <Button
            onClick={handleSaveToDb}
            color="success"
            disabled={isPending}
            enforceWhenLocationsControls={true}
            isTranslatable={false}
        >
            Speichern
        </Button>
    );
};
