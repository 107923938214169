import React, { useCallback } from 'react';
import { Button } from '../../controls/button/Button';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useTranslationSaveCategoryName } from '../adapters/useTranslationSaveCategoryName';

interface ITranslationSaveButtonCategoryName {}

export const TranslationSaveButtonCategoryName: React.FC<
    ITranslationSaveButtonCategoryName
> = () => {
    const { translationKey } = useTranslationEditorState();
    const categoryID = translationKey ? (translationKey[0] as string) : '';

    const { saveTranslationForCategoryName, isPending } =
        useTranslationSaveCategoryName(categoryID);

    const handleSaveToDb = useCallback(() => {
        void saveTranslationForCategoryName();
    }, [saveTranslationForCategoryName]);

    return (
        <Button
            onClick={handleSaveToDb}
            color="success"
            disabled={isPending}
            enforceWhenLocationsControls={true}
            isTranslatable={false}
        >
            Speichern
        </Button>
    );
};
