import React, { createRef, useEffect, useMemo } from 'react';
import { EditorModal } from '../modals/EditorModal';
import { useTranslationEditorState } from './state/TranslationEditor.state';
import { useLocalisationState } from './state/localisation.state';
import { TranslationEditorAdapter } from './adapters/useTranslationEditorAdapter';
import { TranslationSaveButtonAdapter } from './saveAdapters/TranslationSaveButtonAdapter';
import { TranslationEditorLinks } from './elements/TranslationEditorLinks';
import { ETranslationType } from './enum/translations.enums';

export interface ITranslationEditor {}

export const TranslationEditor: React.FC<ITranslationEditor> = () => {
    const { open, close, translationKey, translations, translationType } =
        useTranslationEditorState();

    const { userLanguageOptions } = useLocalisationState();

    const translationFieldRefs = useMemo(() => {
        return userLanguageOptions
            ? userLanguageOptions.map(() => createRef<HTMLInputElement>())
            : [];
    }, [userLanguageOptions]);

    // TODO This should be moved into the Adapter
    const defaultTranslation: string = useMemo(() => {
        const defaultLanguage = userLanguageOptions?.find((el) => el.default);
        const defaultT = translations?.find(
            (el) => el.languageCode === defaultLanguage?.languageCode
        );
        return defaultT?.translation ?? '';
    }, [translations, userLanguageOptions]);

    useEffect(() => {
        if (open) {
            // Add delay to ensure refs are correctly assigned to the current value
            const timeoutId = setTimeout(() => {
                if (translationFieldRefs[0]?.current) {
                    translationFieldRefs[0]?.current?.focus();
                }
            }, 300);

            // Cleanup the timeout
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [open, translationFieldRefs]);

    const headline = useMemo(() => {
        switch (translationType) {
            case ETranslationType.categoryDescription:
                return 'Kategorie Beschreibung';
            case ETranslationType.productLabelName:
                return 'Label Name';
            default:
                return defaultTranslation ? defaultTranslation : translationKey?.[0];
        }
    }, [defaultTranslation, translationKey, translationType]);

    return (
        <EditorModal
            show={open}
            closeFunction={close}
            closeable={true}
            width="narrow"
            headline={headline}
        >
            <section className="relative flex w-full flex-col gap-4">
                {translationType && <TranslationEditorAdapter translationType={translationType} />}
                {translationType && (
                    <TranslationSaveButtonAdapter translationType={translationType} />
                )}
                <TranslationEditorLinks />
            </section>
        </EditorModal>
    );
};
