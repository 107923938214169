import React, { useEffect, useState } from 'react';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { Typography } from '../../typo/Typography/Typography';
import { useLocalisationState } from '../state/localisation.state';
import { useLanguageName } from '../../hooks/localisation/useLanguageName';
import { FlagSymbol } from '../../elements/FlagSymbol/FlagSymbol';
import SlateEditorInPlace from '../../controls/RichText/SlateEditorInPlace';
import { useTranslationEditorFormPopulateCategoryDescription } from './hooks/useTranslationEditorFormPopulateCategoryDescription';
import { ERichTextStyles } from '../../controls/RichText/RichTextDisplay';
import { createMinimalSlateNode } from '../../controls/RichText/helper/slateConstructIsEmpty';
import { Descendant } from 'slate';
import { TRichTextDataObject } from '../../controls/RichText/helper/useRichTextDataTypeAnalyzer';

interface ITranslationEditorFormCategoryDescriptionAdapter {}

export const TranslationEditorFormCategoryDescriptionAdapter: React.FC<
    ITranslationEditorFormCategoryDescriptionAdapter
> = () => {
    const { translations, setTranslationValueByKey } = useTranslationEditorState();
    const [isInitialized, setIsInitialized] = useState(false);

    const { userLanguageOptions, getCurrentLanguage } = useLocalisationState();
    const { getLanguageName } = useLanguageName(getCurrentLanguage().languageCode);

    // Use the hook to populate category description data
    useTranslationEditorFormPopulateCategoryDescription();

    // Set initialized after data has been loaded
    useEffect(() => {
        if (translations && translations.length > 0) {
            setIsInitialized(true);
        }
    }, [translations]);

    const isDefaultLanguage = (languageCode: string): boolean => {
        return (
            userLanguageOptions?.find((el) => el.languageCode === languageCode)?.default ?? false
        );
    };

    // Function to safely parse Slate JSON
    const parseSlateContent = (descendants: Descendant[]) => {
        if (!descendants || descendants.length === 0) {
            return createMinimalSlateNode();
        }

        return descendants;
    };

    return (
        <div className="relative flex w-full flex-col gap-4 border-b border-dotted pb-8 border-cloudbarPrimary">
            <Typography variant="h5">Kategorie Beschreibung</Typography>

            {userLanguageOptions &&
                userLanguageOptions.length > 0 &&
                userLanguageOptions.map((el) => {
                    const name = `${getLanguageName(el.languageCode)}${
                        el.default ? ' - STANDARDSPRACHE' : ''
                    }`;

                    const currentTranslation = translations?.find(
                        (t) => t.languageCode === el.languageCode
                    );

                    let parsedTranslation: {
                        type: string;
                        content: Descendant[];
                    } = {
                        type: 'slatejs',
                        content: [],
                    };

                    // TODO Error
                    if (currentTranslation) {
                        parsedTranslation = JSON.parse(currentTranslation.translation) as {
                            type: string;
                            content: Descendant[];
                        };
                    }

                    // Parse JSON stringified Slate value if it exists
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const slateInitialValue: any[] = parseSlateContent(parsedTranslation?.content);

                    return (
                        <div key={`translation-${el.languageCode}`} className="mb-4">
                            <div className="mb-2 flex items-center gap-2">
                                <FlagSymbol countryCode={el.languageCode} />
                                <Typography variant="label" className="font-medium">
                                    {name}
                                </Typography>
                            </div>
                            <SlateEditorInPlace
                                key={`slate-${el.languageCode}-${isInitialized}`}
                                label=""
                                initialValue={slateInitialValue}
                                onChange={() => {}}
                                onSave={(output) => {
                                    // TODO Normalize... :/
                                    const normalizedOutput = {
                                        type: output?.type ?? 'slatejs',
                                        content: JSON.parse(output?.content ?? '[]') as string,
                                    } as TRichTextDataObject;

                                    if (output?.content) {
                                        setTranslationValueByKey(
                                            el.languageCode,
                                            JSON.stringify(normalizedOutput)
                                        );
                                    }
                                }}
                                allowEdit={true}
                                styles={ERichTextStyles.pageStyles}
                                required={el.default}
                                baseTranslationExists={isDefaultLanguage(el.languageCode)}
                                sourceLanguage={
                                    userLanguageOptions.find((lang) => lang.default)
                                        ?.languageCode || 'de-DE'
                                }
                                targetLanguage={el.languageCode}
                                buttons={{
                                    bold: true,
                                    bulletedList: false,
                                    numberedList: false,
                                    italic: true,
                                    headingOne: true,
                                    headingTwo: true,
                                    underline: true,
                                }}
                            />
                        </div>
                    );
                })}
        </div>
    );
};
