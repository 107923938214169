import { useApi } from '../../../general/hooks/api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IProductDB } from '../../../../interfaces/product/IProduct';
import { toast } from 'react-toastify';
import { gql } from 'graphql-request';
import { ITranslationInput } from '../../../general/Translations/types/translation.interfaces';

interface ProductLabelUpdateInput {
    productID: string;
    labelID: string;
    name?: string;
    nameTranslation?: ITranslationInput;
    color?: string;
    startsAt?: string;
    expiresAt?: string;
}

export const useProductLabelUpdateMutation = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (input: ProductLabelUpdateInput) => {
            const response = await postGql<{
                productLabelUpdate: IProductDB;
            }>(
                'productLabelUpdate',
                gql`
                    mutation ($input: ProductLabelUpdateInput!) {
                        productLabelUpdate(input: $input) {
                            id
                            name
                            labels {
                                labelID
                                name
                                nameTranslation {
                                    baseTranslation
                                    languageCode
                                    translationKey
                                    group
                                    tags
                                    translations {
                                        languageCode
                                        translation
                                    }
                                }
                                color
                                startsAt
                                expiresAt
                            }
                        }
                    }
                `,
                { input }
            );

            return response.productLabelUpdate;
        },
        onSuccess: (data) => {
            void queryClient.invalidateQueries({ queryKey: ['product', data.id] });
            void queryClient.invalidateQueries({ queryKey: ['products'] });
        },
        onError: (error) => {
            toast.error('Failed to update product label: ' + error.message);
        },
    });
};
