import { useCallback, useEffect, useState } from 'react';
import { useTranslationEditorState } from '../../state/TranslationEditor.state';
import { ETranslationType } from '../../enum/translations.enums';
import { useProductQuery } from '../../../hooks/product/useProductQuery';
import { useLocalisationState } from '../../state/localisation.state';
import { ITranslation } from '../../../../../interfaces/translation/translations.interfaces';
import { IProductField } from '../../../../../interfaces/product/IProduct';

export const useTranslationEditorFormPopulateProductFieldValue = () => {
    const { translationType, translationKey, setTranslations } = useTranslationEditorState();
    const { userLanguageOptions, tenantDefaultLanguage } = useLocalisationState();

    const productId = translationKey?.[0];
    const fieldName = translationKey?.[1];

    const { data: product, isPending } = useProductQuery(productId);

    const [init, setInit] = useState<null | string>(null);

    const isEmptyValue = (value: string | undefined): boolean => {
        if (!value) return true;
        if (value === '') return true;
        
        // Check for empty Slate construct (could be extended if needed for other complex data formats)
        try {
            const parsed = JSON.parse(value) as unknown;
            if (Array.isArray(parsed) && parsed.length === 0) return true;
            if (parsed && typeof parsed === 'object' && Object.keys(parsed as Record<string, unknown>).length === 0) return true;
        } catch {
            // Not a JSON string, continue
        }
        
        return false;
    };

    const getFieldTranslations = useCallback((field: IProductField, parentField?: IProductField) => {
        const translationsMap = new Map<string, string>();
        const defaultLanguageCode = tenantDefaultLanguage?.languageCode;

        // First process the current field translations
        if (field.valueTranslation?.translations) {
            field.valueTranslation.translations.forEach((translation) => {
                translationsMap.set(translation.languageCode, translation.translation);
            });
        }

        if (field.valueTranslation?.baseTranslation && defaultLanguageCode) {
            translationsMap.set(
                defaultLanguageCode,
                field.valueTranslation.baseTranslation
            );
        }

        // If there are no translations but field has a value, use it as default translation
        if (field.value && translationsMap.size === 0 && defaultLanguageCode) {
            translationsMap.set(defaultLanguageCode, String(field.value));
        }

        // If this is a variant product and we have a parent field, inherit values for missing translations
        if (parentField) {
            // Add translations from parent field where child translations are empty or missing
            if (parentField.valueTranslation?.translations) {
                parentField.valueTranslation.translations.forEach((translation) => {
                    const existingValue = translationsMap.get(translation.languageCode);
                    if (isEmptyValue(existingValue)) {
                        translationsMap.set(translation.languageCode, translation.translation);
                    }
                });
            }

            // Inherit base translation from parent if empty in child
            if (
                parentField.valueTranslation?.baseTranslation && 
                defaultLanguageCode &&
                isEmptyValue(translationsMap.get(defaultLanguageCode))
            ) {
                translationsMap.set(
                    defaultLanguageCode,
                    parentField.valueTranslation.baseTranslation
                );
            }

            // If no translation exists at all but parent has a value, use it
            if (
                parentField.value && 
                translationsMap.size === 0 && 
                defaultLanguageCode
            ) {
                translationsMap.set(defaultLanguageCode, String(parentField.value));
            }
        }

        return translationsMap;
    }, [tenantDefaultLanguage]);

    const populate = useCallback(() => {
        if (
            !product ||
            !userLanguageOptions ||
            !userLanguageOptions.length ||
            !tenantDefaultLanguage ||
            !fieldName
        ) {
            return;
        }

        const field = product.fields.find((f) => f.name === fieldName);

        if (!field) {
            return;
        }

        let translationsMap = new Map<string, string>();
        
        // Check if this is a variant product (has parent)
        if (product.parent && product.parentProductID) {
            // Find the same field in the parent product
            const parentField = product.parent.fields.find((f) => f.name === fieldName);
            
            if (parentField) {
                // Get translations with inheritance logic
                translationsMap = getFieldTranslations(field, parentField);
            } else {
                // If parent doesn't have this field, just use the child's translations
                translationsMap = getFieldTranslations(field);
            }
        } else {
            // Not a variant, just use the field's own translations
            translationsMap = getFieldTranslations(field);
        }

        const fullTranslations: ITranslation[] = userLanguageOptions.map((language) => {
            return {
                languageCode: language.languageCode,
                translation: translationsMap.get(language.languageCode) || '',
            };
        });

        // Set the translations in the state
        setTranslations(fullTranslations);
    }, [product, userLanguageOptions, tenantDefaultLanguage, setTranslations, fieldName, getFieldTranslations]);

    useEffect(() => {
        if (isPending) return undefined;

        // Reset init state when translation key changes
        if (productId && fieldName && init !== `${productId}-${fieldName}`) {
            if (translationType === ETranslationType.productFieldValue) {
                setInit(`${productId}-${fieldName}`);
                populate();
            }
        }
    }, [init, isPending, populate, translationKey, translationType, productId, fieldName]);
};
