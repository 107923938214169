import React, { useCallback } from 'react';
import { Button } from '../../controls/button/Button';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useTranslationSaveCategoryDescription } from '../adapters/useTranslationSaveCategoryDescription';

interface ITranslationSaveButtonCategoryDescription {}

export const TranslationSaveButtonCategoryDescription: React.FC<
    ITranslationSaveButtonCategoryDescription
> = () => {
    const { translationKey } = useTranslationEditorState();
    const categoryID = translationKey ? (translationKey[0] as string) : '';

    const { saveTranslationForCategoryDescription, isPending } =
        useTranslationSaveCategoryDescription(categoryID);

    const handleSaveToDb = useCallback(() => {
        void saveTranslationForCategoryDescription();
    }, [saveTranslationForCategoryDescription]);

    return (
        <Button
            onClick={handleSaveToDb}
            color="success"
            disabled={isPending}
            enforceWhenLocationsControls={true}
            isTranslatable={false}
        >
            Speichern
        </Button>
    );
};