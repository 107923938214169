import { useCallback } from 'react';
import { useLocalisationState } from '../state/localisation.state';
import { useTranslation } from './useTranslation';

export const useTranslationFieldsEmptyDetector = () => {
    const { userLanguageOptions } = useLocalisationState();
    const { getT } = useTranslation();

    const isTranslationKeyEmpty = useCallback(
        (translationKey: string[] | undefined, index: number) => {
            if (!translationKey) return true;
            const key = translationKey[index] ?? '';

            // Iterate all userLanguageOptions and check if the current translationKey is empty
            return userLanguageOptions?.every((el) => {
                const translation = getT(key, el.languageCode);

                // Checking if the translation is null or an empty string
                return !translation || translation.length === 0;
            });
        },
        [getT, userLanguageOptions]
    );

    return { isTranslationKeyEmpty };
};
