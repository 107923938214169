import React, { ReactElement } from 'react';
import {
    CodeBracketIcon,
    DocumentCheckIcon,
    ListBulletIcon,
    XMarkIcon,
} from '@heroicons/react/16/solid';
import { ReactEditor, useSlate } from 'slate-react';
import { SlateButton } from '../SlateButton';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { Transition } from '@headlessui/react';
import { Button } from '../../button/Button';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export type TSlateButtons = {
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    code?: boolean;
    headingOne?: boolean;
    headingTwo?: boolean;
    blockQuote?: boolean;
    numberedList?: boolean;
    bulletedList?: boolean;
    left?: boolean;
    center?: boolean;
    right?: boolean;
    justify?: boolean;
    paragraph?: boolean;
};

const isBlockActive = (editor: Editor, format: string, blockType = 'type') => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
        })
    );

    return !!match;
};

const isMarkActive = (editor: Editor, format: string) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: Editor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const toggleBlock = (editor: Editor, format: string) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    );

    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            LIST_TYPES.includes(n.type as string) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    });

    let newProperties: Partial<SlateElement>;

    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            align: isActive ? undefined : format,
        };
    } else {
        newProperties = {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        };
    }

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };

        Transforms.wrapNodes(editor, block);
    }
};

// eslint-disable-next-line react/no-multi-comp
const BlockButton = ({ format, icon }: { format: string; icon: ReactElement }) => {
    const editor = useSlate();

    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    );

    return (
        <SlateButton
            isActive={isActive}
            onClick={() => {
                toggleBlock(editor, format);
            }}
        >
            <span className="h-4">{icon}</span>
        </SlateButton>
    );
};

// eslint-disable-next-line react/no-multi-comp
const MarkButton = ({ format, icon }: { format: string; icon: ReactElement }) => {
    const editor = useSlate();

    return (
        <SlateButton
            isActive={isMarkActive(editor, format)}
            onClick={() => {
                toggleMark(editor, format);
            }}
        >
            <span className="h-4">{icon}</span>
        </SlateButton>
    );
};

export interface ISlateButtonRow {
    editor: ReactEditor & HistoryEditor;
    buttons: TSlateButtons;
    show: boolean;
    showSaveButton?: boolean;
    onSave?: () => void;
    close?: () => void;
}

// eslint-disable-next-line react/no-multi-comp
export const SlateButtonRow: React.FC<ISlateButtonRow> = (props) => {
    const { buttons, show, showSaveButton, onSave, close } = props;

    return (
        <Transition
            as={'div'}
            className="absolute -top-20 left-0 z-40 flex bg-neutral-100 p-1 shadow-md shadow-neutral-800 backdrop-blur-sm"
            show={show ?? false}
            appear={show ?? false}
            enter="transition ease-out duration-500"
            enterFrom="transform opacity-0 translate-y-20"
            enterTo="transform opacity-100 translate-y-0"
            leave="transition ease-in duration-300"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0 -translate-y-32"
            data-test-id="SlateButtonRow"
        >
            <svg
                className="absolute -bottom-6 left-10 rotate-180"
                width="30"
                height="30"
                viewBox="0 0 200 200"
                xmlns="http://www.w3.org/2000/svg"
            >
                <polygon
                    points="100,100 180,180 20,180"
                    fill="#f5f5f5"
                    stroke="black"
                    stroke-width="0"
                />
            </svg>

            <div className="relative flex w-full flex-row items-center gap-1 p-2">
                {buttons?.bold && (
                    <MarkButton
                        format="bold"
                        icon={<span className="relative -top-1 text-xl font-bold">b</span>}
                    />
                )}
                {buttons?.italic && (
                    <MarkButton
                        format="italic"
                        icon={<span className="relative -top-1 text-xl italic">i</span>}
                    />
                )}
                {buttons?.underline && (
                    <MarkButton
                        format="underline"
                        icon={<span className="relative text-xl underline -top-1.5">u</span>}
                    />
                )}

                {buttons?.code && (
                    <MarkButton format="code" icon={<CodeBracketIcon className="h-4 w-4" />} />
                )}
            </div>
            <div className="relative flex w-full flex-row items-center gap-1 p-2">
                {buttons?.paragraph && (
                    <BlockButton
                        format="paragraph"
                        icon={<span className="relative -top-1 text-md">Paragraph</span>}
                    />
                )}

                {buttons?.headingOne && (
                    <BlockButton
                        format="heading-one"
                        icon={<span className="relative -top-1 font-bold text-md">H1</span>}
                    />
                )}

                {buttons?.headingTwo && (
                    <BlockButton
                        format="heading-two"
                        icon={<span className="relative -top-1 font-medium text-md">H2</span>}
                    />
                )}
                {buttons?.blockQuote && (
                    <BlockButton format="block-quote" icon={<span>{'"'}</span>} />
                )}
                {buttons?.numberedList && (
                    <BlockButton format="numbered-list" icon={<span>{'1,2,3'}</span>} />
                )}
                {buttons?.bulletedList && (
                    <BlockButton
                        format="bulleted-list"
                        icon={<ListBulletIcon className="h-4 w-4" />}
                    />
                )}
                {buttons?.left && <BlockButton format="left" icon={<span></span>} />}
                {buttons?.center && <BlockButton format="center" icon={<span></span>} />}
                {buttons?.right && <BlockButton format="right" icon={<span></span>} />}
                {buttons?.justify && <BlockButton format="justify" icon={<span></span>} />}

                <div className="ml-4 h-full bg-neutral-300 w-[1px]" />

                {showSaveButton && (
                    <div className="relative ml-4 flex flex-row items-center gap-1">
                        <Button
                            testId={`slate-cancelButton`}
                            color="neutral"
                            onClick={() => {
                                if (close) close();
                            }}
                            iconStart={<XMarkIcon className="relative -left-1 h-6" />}
                            isTranslatable={false}
                        >
                            Cancel
                        </Button>

                        <Button
                            testId={`slate-saveButton`}
                            color="success"
                            isTranslatable={false}
                            onClick={() => {
                                if (onSave) onSave();
                            }}
                            iconStart={<DocumentCheckIcon className="relative -left-1 h-6" />}
                        >
                            Save
                        </Button>
                    </div>
                )}
            </div>
        </Transition>
    );
};
