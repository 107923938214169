import { create } from 'zustand';
import { ETranslationEntity } from '../types/translationApply.types';
import { ETranslationType } from '../enum/translations.enums';
import { ITranslation } from '../../../../interfaces/translation/translations.interfaces';
import { ITranslationInput } from '../types/translation.interfaces';

interface ITranslationEditorState {
    open: boolean;
    close: () => void;
    openEditor: () => void;
    set: (state: Partial<ITranslationEditorState>) => void;
    translationKey?: string[];
    translationType?: ETranslationType;
    translationEntity?: ETranslationEntity;
    translations: ITranslation[] | null; // de-DE, en-US, ...
    setTranslations: (translations: ITranslation[]) => void;
    setTranslationValueByKey: (key: string, value: string) => void;
    onSave?: (translation: ITranslationInput | undefined) => void;
    setOnSave?: (onSave: () => void) => void;
}

export const useTranslationEditorState = create<ITranslationEditorState>((set) => ({
    open: false,
    close: () => set({ 
        open: false, 
        onSave: undefined, 
        translationType: ETranslationType.static,
        translations: null,
        translationKey: undefined,
        translationEntity: undefined
    }),
    openEditor: () => set({ open: true }),
    translationType: ETranslationType.static,
    set: (state) => set(state),
    translations: null,
    setTranslations: (translations) => set({ translations }),
    setTranslationValueByKey: (key, value) => {
        set((state) => {
            if (!state.translations) return state;

            // Check if the key exists in the translations
            const keyExists = state.translations.some((t) => t.languageCode === key);

            if (keyExists) {
                // Update existing translation
                const newTranslations = state.translations.map((t) => {
                    if (t.languageCode === key) {
                        return { ...t, translation: value };
                    }
                    return t;
                });
                return { translations: newTranslations };
            } else {
                // Add new translation with the given key
                const newTranslation: ITranslation = {
                    languageCode: key,
                    translation: value,
                };
                return { translations: [...state.translations, newTranslation] };
            }
        });
    },
    onSave: undefined,
    setOnSave: (onSave) => set({ onSave }),
}));
