import { Descendant } from 'slate';
import { TRichTextDataObject } from './useRichTextDataTypeAnalyzer';
import { isJSON } from '../../../../../helper/isJSON';

// TODO Don't touch this function.
export const slateConstructIsEmpty = (descendents: Descendant[]): boolean => {
    const needles = [
        '{}',
        '{"type":"paragraph","children":[{"text":""}]}',
        '{"type":"heading-one","children":[{"text":""}]}',
        '{"type":"heading-one","children":[{"text":"","type":"heading-one"}]}',
    ];

    if (descendents.length === 0) {
        return true;
    }

    if (descendents.length > 1) {
        return false;
    }

    if (descendents.length === 1) {
        return needles.includes(JSON.stringify(descendents[0]));
    }

    return false;
};

/**
 * Helper function to check if Descendant[] array is empty
 */
const checkDescendantArrayIsEmpty = (descendants: Descendant[]): boolean => {
    return slateConstructIsEmpty(descendants);
};

/**
 * Helper function to check if TRichTextDataObject is empty
 */
const checkRichTextDataObjectIsEmpty = (dataObject: TRichTextDataObject): boolean => {
    if (!dataObject || !dataObject.content) {
        return true;
    }

    if (dataObject.type === 'slatejs') {
        try {
            // Parse the content if it's a JSON string
            if (typeof dataObject.content === 'string') {
                const parsedContent = JSON.parse(dataObject.content) as Descendant[];
                return checkDescendantArrayIsEmpty(parsedContent);
            }
        } catch (e) {
            // If parsing fails, check if the content string is empty
            return !dataObject.content.trim();
        }
    } else if (dataObject.type === 'html') {
        // For HTML type, check if it's just whitespace
        return !dataObject.content.trim();
    }

    // Default case: assume not empty if content exists
    return !dataObject.content.trim();
};

/**
 * Parses and checks if Slate construct is empty based on various input formats
 * @param input Can be a string (plain text, JSON string of TRichTextDataObject, or JSON string of Descendant[]) or Descendant[] array
 * @returns boolean indicating if the construct is empty
 */
export const parseAndCheckSlateConstruct = (input: string | Descendant[] | TRichTextDataObject): boolean => {
    // Case: Input is already a Descendant[] array
    if (Array.isArray(input)) {
        return checkDescendantArrayIsEmpty(input);
    }

    // Case: Input is a TRichTextDataObject
    if (typeof input === 'object' && 'type' in input && 'content' in input) {
        return checkRichTextDataObjectIsEmpty(input);
    }

    // Case: Input is a string
    if (typeof input === 'string') {
        // Case 1A: Plain text (not JSON)
        if (!isJSON(input)) {
            return input.trim() === '';
        }

        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const parsedData = JSON.parse(input);

            // Case 1B: TRichTextDataObject as JSON string
            if (
                typeof parsedData === 'object' && 
                !Array.isArray(parsedData) && 
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                'type' in parsedData && 
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                'content' in parsedData
            ) {
                return checkRichTextDataObjectIsEmpty(parsedData as TRichTextDataObject);
            }

            // Case 1C: Descendant[] as JSON string
            if (Array.isArray(parsedData)) {
                return checkDescendantArrayIsEmpty(parsedData as unknown as Descendant[]);
            }

            // Empty object case
            if (typeof parsedData === 'object') {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return Object.keys(parsedData).length === 0;
            }
        } catch (e) {
            // If parsing fails, treat as plain text
            return input.trim() === '';
        }
    }

    // Default case: unable to determine format, consider not empty
    return false;
};

/**
 * Creates a minimal valid Slate node structure based on the provided node type
 * @param nodeType The type of node to create ('paragraph', 'heading-one', etc.)
 * @returns A valid Slate document structure with a single empty node
 */
export const createMinimalSlateNode = (nodeType: string = 'paragraph'): Descendant[] => {
    return [
        {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Type safety is handled by Slate internally
            type: nodeType,
            children: [{ text: '' }],
        },
    ];
};

/**
 * Ensures the provided initial value is valid for Slate editor
 * If initialValue is empty or invalid, returns a minimal valid structure
 * @param initialValue The initial value to sanitize
 * @param defaultNodeType The default node type to use if creating a new structure
 * @returns A valid Slate document structure
 */
export const sanitizeSlateInitialValue = (
    initialValue: Descendant[] | undefined | null,
    defaultNodeType: string = 'paragraph'
): Descendant[] => {
    // If initialValue is undefined, null, or empty array, return minimal structure
    if (!initialValue || initialValue.length === 0) {
        return createMinimalSlateNode(defaultNodeType);
    }

    // Return the initialValue as is (it has content)
    return initialValue;
};