import { useCallback, useState } from 'react';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useLocalisationState } from '../state/localisation.state';
import { toast } from 'react-toastify';
import { useTranslation } from '../hooks/useTranslation';
import { useProductQuery } from '../../hooks/product/useProductQuery';
import { useProductFieldSetValueMutation } from '../../../admin/hooks/productField/useProductFieldSetValueMutation';
import { IProduct, IProductField } from '../../../../interfaces/product/IProduct';

export const useTranslationSaveProductFieldValue = () => {
    const { translations, onSave, close, translationKey } = useTranslationEditorState();

    const productId = translationKey?.[0] ?? '';
    const fieldName = translationKey?.[1] ?? '';

    const [isPending, setIsPending] = useState(false);

    // Get localization state
    const { tenantDefaultLanguage } = useLocalisationState();
    const { getT } = useTranslation();

    // Get product update mutation and product data
    const { mutateAsync: updateProductField, isPending: isMutationPending } =
        useProductFieldSetValueMutation(productId);
    const { data: product } = useProductQuery(productId);

    // Helper to check if a value is empty (string or object)
    const isEmptyValue = useCallback((value: string | undefined): boolean => {
        if (!value) return true;
        if (value === '') return true;

        // Check for empty Slate construct (could be extended if needed for other complex data formats)
        try {
            const parsed = JSON.parse(value) as unknown;
            if (Array.isArray(parsed) && parsed.length === 0) return true;
            if (
                parsed &&
                typeof parsed === 'object' &&
                Object.keys(parsed as Record<string, unknown>).length === 0
            )
                return true;
        } catch {
            // Not a JSON string, continue
        }

        return false;
    }, []);

    // Determine if a field has any values set
    const hasAnyTranslationValues = useCallback(
        (field: IProductField): boolean => {
            // Check if base translation exists and is not empty
            if (
                field.valueTranslation?.baseTranslation &&
                !isEmptyValue(field.valueTranslation.baseTranslation)
            ) {
                return true;
            }

            // Check if any translations exist and are not empty
            if (field.valueTranslation?.translations) {
                for (const translation of field.valueTranslation.translations) {
                    if (!isEmptyValue(translation.translation)) {
                        return true;
                    }
                }
            }

            return false;
        },
        [isEmptyValue]
    );

    const saveProductFieldValueTranslation = useCallback(async () => {
        // Helper function to determine the target product (moved inside useCallback)
        const determineTargetProduct = (
            childProduct: IProduct,
            childField: IProductField
        ): { targetProductId: string; targetField: IProductField } => {
            // If this is not a variant product (no parent), save directly to this product
            if (!childProduct.parent || !childProduct.parentProductID) {
                return { targetProductId: childProduct.id, targetField: childField };
            }

            // This is a variant product with a parent
            // Check if field exists in the parent
            const parentField = childProduct.parent.fields.find((f) => f.name === childField.name);

            // If parent doesn't have this field, save to child
            if (!parentField) {
                return { targetProductId: childProduct.id, targetField: childField };
            }

            // If child has existing translations, save to child (override parent)
            if (hasAnyTranslationValues(childField)) {
                return { targetProductId: childProduct.id, targetField: childField };
            }

            // If child has no translations but parent does, we're modifying inherited values
            // In this case we want to modify the parent's values since that's where the inherited data comes from
            return {
                targetProductId: childProduct.parent.id,
                targetField: parentField,
            };
        };
        if (!translations || !tenantDefaultLanguage || !fieldName) {
            return;
        }

        if (isMutationPending) {
            return;
        }

        setIsPending(true);

        try {
            if (!product) {
                throw new Error(`Product ${productId} not found`);
            }

            // Get the default language translation
            const defaultTranslation = translations.find(
                (t) => t.languageCode === tenantDefaultLanguage.languageCode
            );

            if (!defaultTranslation) {
                throw new Error('Default language translation is required');
            }

            // Get all non-default language translations
            const otherTranslations = translations.filter(
                (t) => t.languageCode !== tenantDefaultLanguage.languageCode
            );

            // Find the field in the product by name
            const field = product.fields.find((f) => f.name === fieldName);

            if (!field) {
                throw new Error(`Field ${fieldName} not found in product ${productId}`);
            }

            // Determine which product to save to (parent or child)
            const { targetProductId, targetField } = determineTargetProduct(product, field);

            // Create mutation payload
            const mutationPayload = {
                productID: targetProductId,
                fieldName,
                fieldValue: targetField.value,
                visible: targetField.visible ?? true,
                fieldValueTranslation: {
                    baseTranslation: defaultTranslation.translation,
                    languageCode: tenantDefaultLanguage.languageCode,
                    translations: otherTranslations.map((t) => ({
                        languageCode: t.languageCode,
                        translation: t.translation,
                    })),
                },
            };

            if (onSave) {
                onSave(mutationPayload.fieldValueTranslation ?? undefined);
            }

            // Call the mutation with only the field we want to update
            await updateProductField(mutationPayload).then(() => {
                toast.success(
                    getT('toastSuccessProductFieldValueTranslationsSaved') ??
                        'Die Übersetzungen des Produktfeldwerts wurden erfolgreich gespeichert.',
                    {
                        toastId: 'admin-product-field-value-translation-update-success',
                        autoClose: 2000,
                    }
                );
                close();
            });
        } catch (error) {
            console.error(error);
            toast.error(
                getT('toastErrorWhenSavingProductFieldValueTranslationsUpdates') ??
                    'Fehler beim Speichern von Produktfeldwertübersetzungen.',
                {
                    toastId: 'admin-product-field-value-translations-update-error',
                    autoClose: 5000,
                }
            );
        } finally {
            setIsPending(false);
        }
    }, [
        translations,
        tenantDefaultLanguage,
        fieldName,
        isMutationPending,
        onSave,
        productId,
        product,
        updateProductField,
        getT,
        close,
        hasAnyTranslationValues,
    ]);

    return {
        saveProductFieldValueTranslation,
        isPending: isPending || isMutationPending,
    };
};
