import React, { useCallback } from 'react';
import { Button } from '../../controls/button/Button';
import { useTranslationSaveProductFieldValue } from '../adapters/useTranslationSaveProductFieldValue';
import { useTranslation } from '../hooks/useTranslation';
import { useQueryClient } from '@tanstack/react-query';

export const TranslationSaveButtonProductFieldValue: React.FC = () => {
    const { saveProductFieldValueTranslation, isPending } = useTranslationSaveProductFieldValue();
    const { getT } = useTranslation();

    const queryClient = useQueryClient();

    const handleSaveToDb = useCallback(() => {
        void saveProductFieldValueTranslation().then(() => {
            void queryClient.invalidateQueries({
                queryKey: ['product'],
            });
        });
    }, [queryClient, saveProductFieldValueTranslation]);

    return (
        <Button
            onClick={handleSaveToDb}
            color="success"
            disabled={isPending}
            enforceWhenLocationsControls={true}
            iconSpin={isPending}
            isTranslatable={false}
        >
            {getT('save') ?? 'Speichern'}
        </Button>
    );
};
