import React from 'react';
import Link from 'next/link';
import { LinkIcon } from '@heroicons/react/20/solid';

export const TranslationEditorLinks: React.FC = () => {
    return (
        <>
            <Link
                className="mt-4 flex flex-row gap-1 text-sm font-semibold text-primary hover:underline"
                href="/admin/localisation"
                target="_blank"
            >
                <LinkIcon className="h-5" />
                Verfügbare Sprachen verwalten
            </Link>
            <Link
                className="mt-4 flex flex-row gap-1 text-sm font-semibold text-primary hover:underline"
                href="/admin/translations"
                target="_blank"
            >
                <LinkIcon className="h-5" />
                Übersetzungsschlüssel suchen
            </Link>
        </>
    );
};
