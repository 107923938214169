import { useApi } from '../../../../general/hooks/api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IProductUpdateInput } from '../../../../../interfaces/product/api/IProductUpdateInput';
import { IProductDB } from '../../../../../interfaces/product/IProduct';
import { toast } from 'react-toastify';
import { mapProductFromDBToFE } from '../../../../../interfaces/product/maps';
import { gql } from 'graphql-request';
import { VARIANTS_FRAGMENT } from '../../../../general/hooks/product/queries/productVariant.query';

export const useProductUpdateMutation = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (input: IProductUpdateInput) => {
            const response = await postGql<{
                productUpdate: IProductDB;
            }>(
                'productUpdate',
                gql`
                    mutation ($input: ProductUpdateInput!) {
                        productUpdate(input: $input) {
                            published
                            id
                            code
                            type
                            orderable
                            hidePlaceControls
                            name
                            created
                            accessRoleIDs
                            defaultOutputPipeline
                            isFavourite
                            archived
                            projectID
                            thumbnailConfig
                            hideItemName
                            parentProductID

                            staticLinkURL

                            deleted

                            descriptionFromParent

                            parent {
                                ...VariantsFields
                                variantProductIDs
                                variants {
                                    ...VariantsFields
                                }
                            }

                            variantProductIDs
                            variants {
                                ...VariantsFields
                            }
                            labels {
                                labelID
                                name
                                nameTranslation {
                                    baseTranslation
                                    languageCode
                                    translationKey
                                    group
                                    tags
                                    translations {
                                        languageCode
                                        translation
                                    }
                                }
                                color
                                startsAt
                                expiresAt
                            }
                            tenantIDs
                            defaultTemplateFile
                            nameTranslation {
                                baseTranslation
                                languageCode
                                translationKey
                                group
                                tags
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                            fields {
                                group
                                name
                                value
                                valueTranslation {
                                    baseTranslation
                                    languageCode
                                    translationKey
                                    group
                                    tags
                                    translations {
                                        languageCode
                                        translation
                                    }
                                }
                                config
                                defaultValue
                                visible
                            }
                            categories {
                                id
                                description
                                name
                            }
                            categoryIDs
                            stock {
                                allowNegative
                                amount
                                warnAt
                            }
                            images {
                                id
                                path
                                tag
                                width
                                height
                            }
                            description
                            descriptionTranslation {
                                baseTranslation
                                languageCode
                                translationKey
                                group
                                tags
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                            priceTableID
                            priceTable {
                                id
                                name
                                scales {
                                    id
                                    quantity
                                    basePrice
                                }
                                options {
                                    id
                                    fieldName
                                    fieldValue
                                    weight
                                    optional
                                    hidden
                                }
                                points {
                                    optionID
                                    scaleID
                                    price
                                }
                            }
                            quantityOptions {
                                mode
                                min
                                max
                                scales
                                stepSize
                            }
                        }
                    }
                    ${VARIANTS_FRAGMENT}
                `,
                { input }
            );

            return mapProductFromDBToFE(response.productUpdate, {
                mapVariants: true,
                mapParent: true,
            });
        },
        onSuccess: () => {
            const key = ['product', 'products', 'product-admin'];
            void queryClient.invalidateQueries({ queryKey: key });
        },
        onError: (error) => {
            let errorMessage = 'Failed to update product';

            if (error.message.includes('code is already in use')) {
                errorMessage = 'Produktcode bereits verwendet';
            }
            toast.error(errorMessage);
        },
    });
};
