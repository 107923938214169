import { useApi } from '../../../general/hooks/api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IGenericFieldBE } from '../../../../interfaces/user/IGenericFieldBE';
import { gql } from 'graphql-request';

import { ITranslationInput } from '../../../general/Translations/types/translation.interfaces';

export interface IProductFieldUpdateInput {
    fieldName: string;
    config?: string;
    defaultValue?: string;
    group?: string;
    tenantIDs?: string[];
    order?: number;
    translation?: ITranslationInput; // Must be a TranslationInput object
    labelTranslation?: ITranslationInput;
}

export const useProductUpdateFieldMutation = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (input: IProductFieldUpdateInput) => {
            let inputForApi = { ...input };

            // Handle label translation by embedding it in config
            if (input.labelTranslation) {
                // Start with either existing config or new default object
                let configObj: Record<string, unknown> = { type: 'text' };

                // Safely parse existing config if available
                if (input.config) {
                    try {
                        // Use type assertion with a type guard
                        const result = JSON.parse(input.config) as unknown;
                        if (result && typeof result === 'object' && !Array.isArray(result)) {
                            configObj = result as Record<string, unknown>;
                        }
                    } catch {
                        // Keep default if parsing fails
                    }
                }

                // Update config with label translation
                configObj.label = input.labelTranslation.baseTranslation;
                configObj.labelTranslation = input.labelTranslation;

                // Remove labelTranslation from the input and create a new object
                const inputWithoutTranslation = { ...input };
                delete inputWithoutTranslation.labelTranslation;
                inputForApi = {
                    ...inputWithoutTranslation,
                    config: JSON.stringify(configObj),
                };
            }

            // Execute GraphQL mutation
            const { productFieldUpdate: data } = await postGql<{
                productFieldUpdate: IGenericFieldBE[];
            }>(
                'productFieldUpdate',
                gql`
                    mutation ($input: FieldGenericUpdateInput!) {
                        productFieldUpdate(input: $input) {
                            fieldName
                            group
                            config
                            defaultValue
                            tenantID
                            order
                        }
                    }
                `,
                { input: inputForApi }
            );
            return data;
        },
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['product'],
            });
        },
    });
};
