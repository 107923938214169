import React from 'react';
import { ETranslationType } from '../enum/translations.enums';
import { TranslationSaveButtonStatic } from './TranslationSaveButtonStatic';
import { TranslationSaveButtonProductName } from './TranslationSaveButtonProductName';
import { TranslationSaveButtonProductFieldValue } from './TranslationSaveButtonProductFieldValue';
import { TranslationSaveButtonProductFieldLabel } from './TranslationSaveButtonProductFieldLabel';
import { TranslationSaveButtonUserFieldLabel } from './TranslationSaveButtonUserFieldLabel';
import { TranslationSaveButtonCategoryName } from './TranslationSaveButtonCategoryName';
import { TranslationSaveButtonCategoryDescription } from './TranslationSaveButtonCategoryDescription';
import { TranslationSaveButtonProductLabelName } from './TranslationSaveButtonProductLabelName';

interface ITranslationSaveButtonAdapter {
    translationType: ETranslationType;
}

export const TranslationSaveButtonAdapter: React.FC<ITranslationSaveButtonAdapter> = ({
    translationType,
}) => {
    switch (translationType) {
        case ETranslationType.static:
            return <TranslationSaveButtonStatic />;
        case ETranslationType.productName:
            return <TranslationSaveButtonProductName />;
        case ETranslationType.productFieldLabel:
            return <TranslationSaveButtonProductFieldLabel />;
        case ETranslationType.productFieldValue:
            return <TranslationSaveButtonProductFieldValue />;
        case ETranslationType.userFieldLabel:
            return <TranslationSaveButtonUserFieldLabel />;
        case ETranslationType.categoryName:
            return <TranslationSaveButtonCategoryName />;
        case ETranslationType.categoryDescription:
            return <TranslationSaveButtonCategoryDescription />;
        case ETranslationType.productLabelName:
            return <TranslationSaveButtonProductLabelName />;
        default:
            return null;
    }
};
