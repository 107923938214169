import { useCallback, useEffect, useState } from 'react';
import { useTranslationEditorState } from '../../state/TranslationEditor.state';
import { ETranslationType } from '../../enum/translations.enums';
import { useLocalisationState } from '../../state/localisation.state';
import {
    ITranslation,
    ITranslations,
} from '../../../../../interfaces/translation/translations.interfaces';
import { useUserGenericFieldsQuery } from '../../../hooks/user/useUserGenericFieldsQuery';

// Define an interface for the parsed config object
interface IFieldConfig {
    label?: string;
    labelTranslation?: ITranslations;
    [key: string]: unknown;
}

export const useTranslationEditorFormPopulateUserFieldLabel = () => {
    const { translationType, translationKey, setTranslations } = useTranslationEditorState();
    const { userLanguageOptions, tenantDefaultLanguage } = useLocalisationState();

    const fieldName = translationKey?.[0];

    const { data: userFields, isLoading: isUserFieldsLoading } = useUserGenericFieldsQuery({
        enabled: true,
        staleTime: 50000,
    });

    const [init, setInit] = useState<null | string>(null);

    const populate = useCallback(() => {
        if (
            !userLanguageOptions ||
            !userLanguageOptions.length ||
            !tenantDefaultLanguage ||
            !fieldName
        ) {
            return;
        }

        const translationsMap = new Map<string, string>();
        let defaultLabel = fieldName;

        // Check if there are existing translations in the user field
        if (userFields) {
            const userField = userFields.find((field) => field.fieldName === fieldName);

            if (userField && userField.config) {
                try {
                    // Parse the config object where labelTranslation is stored
                    let configObj: IFieldConfig | null = null;

                    if (typeof userField.config === 'string') {
                        configObj = JSON.parse(userField.config) as IFieldConfig;
                    } else {
                        configObj = userField.config as unknown as IFieldConfig;
                    }

                    // Get the default label from config.label
                    if (configObj?.label) {
                        defaultLabel = configObj.label;
                        // Use it as fallback for default language
                        if (!translationsMap.has(tenantDefaultLanguage.languageCode)) {
                            translationsMap.set(
                                tenantDefaultLanguage.languageCode,
                                configObj.label
                            );
                        }
                    }

                    // Get translations from labelTranslation in configObj
                    if (configObj?.labelTranslation) {
                        // Process base translation for default language
                        if (
                            configObj.labelTranslation.baseTranslation &&
                            tenantDefaultLanguage.languageCode
                        ) {
                            defaultLabel = configObj.labelTranslation.baseTranslation;
                            translationsMap.set(
                                tenantDefaultLanguage.languageCode,
                                configObj.labelTranslation.baseTranslation
                            );
                        }

                        // Process additional translations (non-default languages)
                        if (
                            configObj.labelTranslation.translations &&
                            Array.isArray(configObj.labelTranslation.translations)
                        ) {
                            configObj.labelTranslation.translations.forEach((translation) => {
                                if (translation.languageCode && translation.translation) {
                                    translationsMap.set(
                                        translation.languageCode,
                                        translation.translation
                                    );
                                }
                            });
                        }
                    }
                } catch (e) {
                    // If JSON parsing fails, keep using the fieldName
                    console.error('Error parsing user field config', e);
                }
            }
        }

        // If still no translations exist, use the defaultLabel as fallback for default language
        if (translationsMap.size === 0 && tenantDefaultLanguage.languageCode) {
            translationsMap.set(tenantDefaultLanguage.languageCode, defaultLabel);
        }

        // Create a complete translation set for all languages
        const fullTranslations: ITranslation[] = userLanguageOptions.map((language) => {
            return {
                languageCode: language.languageCode,
                translation: translationsMap.get(language.languageCode) || '',
            };
        });

        // Set the translations in the state
        setTranslations(fullTranslations);
    }, [userFields, userLanguageOptions, tenantDefaultLanguage, setTranslations, fieldName]);

    useEffect(() => {
        if (isUserFieldsLoading) return undefined;

        // Reset init state when translation key changes
        if (fieldName && init !== fieldName) {
            if (translationType === ETranslationType.userFieldLabel) {
                setInit(fieldName);
                populate();
            }
        }
    }, [init, isUserFieldsLoading, populate, translationKey, translationType, fieldName]);
};
