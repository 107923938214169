import { gql } from 'graphql-request';

export const VARIANTS_FRAGMENT = gql`
    fragment VariantsFields on Product {
        published
        id
        code
        type
        orderable
        hidePlaceControls
        name
        archived
        created
        accessRoleIDs
        defaultOutputPipeline
        isFavourite
        archived
        projectID
        thumbnailConfig
        hideItemName

        deleted

        descriptionFromParent

        labels {
            labelID
            name
            nameTranslation {
                baseTranslation
                languageCode
                translationKey
                group
                tags
                translations {
                    languageCode
                    translation
                }
            }
            color
            startsAt
            expiresAt
        }
        tenantIDs
        defaultTemplateFile
        nameTranslation {
            baseTranslation
            languageCode
            translationKey
            group
            tags
            translations {
                languageCode
                translation
            }
        }
        fields {
            group
            name
            value
            valueTranslation {
                baseTranslation
                languageCode
                translationKey
                group
                tags
                translations {
                    languageCode
                    translation
                }
            }
            config
            defaultValue
            visible
        }
        categories {
            id
            description
            name
        }
        categoryIDs
        stock {
            allowNegative
            amount
            warnAt
        }
        images {
            id
            path
            tag
            width
            height
        }
        description
        priceTableID
        priceTable {
            id
            name
            scales {
                id
                quantity
                basePrice
            }
            options {
                id
                fieldName
                fieldValue
                weight
                optional
                hidden
            }
            points {
                optionID
                scaleID
                price
            }
        }
        quantityOptions {
            mode
            min
            max
            scales
            stepSize
        }
    }
`;
