import React, { useCallback } from 'react';
import { Button } from '../../controls/button/Button';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useTranslationSaveProductLabelName } from '../adapters/useTranslationSaveProductLabelName';

interface ITranslationSaveButtonProductLabelName {}

export const TranslationSaveButtonProductLabelName: React.FC<
    ITranslationSaveButtonProductLabelName
> = () => {
    const { translationKey } = useTranslationEditorState();
    
    // The key structure should be: [productID, labelID]
    const productID = translationKey?.[0] as string;
    const labelID = translationKey?.[1] as string;

    const { saveTranslationForProductLabelName, isPending } =
        useTranslationSaveProductLabelName(productID, labelID);

    const handleSaveToDb = useCallback(() => {
        void saveTranslationForProductLabelName();
    }, [saveTranslationForProductLabelName]);

    return (
        <Button
            onClick={handleSaveToDb}
            color="success"
            disabled={isPending}
            enforceWhenLocationsControls={true}
            isTranslatable={false}
        >
            Speichern
        </Button>
    );
};