import { gql } from 'graphql-request';
import { VARIANTS_FRAGMENT } from './productVariant.query';

export const PRODUCT_QUERY = gql`
    query product($id: ID!) {
        product(id: $id) {
            published
            id
            code
            type
            orderable
            hidePlaceControls
            name
            created
            accessRoleIDs
            defaultOutputPipeline
            isFavourite
            archived
            projectID
            thumbnailConfig
            hideItemName
            parentProductID

            staticLinkURL

            deleted

            descriptionFromParent

            parent {
                ...VariantsFields
                variantProductIDs
                variants {
                    ...VariantsFields
                }
            }

            variantProductIDs
            variants {
                ...VariantsFields
            }
            labels {
                labelID
                name
                nameTranslation {
                    baseTranslation
                    languageCode
                    translationKey
                    group
                    tags
                    translations {
                        languageCode
                        translation
                    }
                }
                color
                startsAt
                expiresAt
            }
            tenantIDs
            defaultTemplateFile
            nameTranslation {
                baseTranslation
                languageCode
                translationKey
                group
                tags
                translations {
                    languageCode
                    translation
                }
            }
            fields {
                group
                name
                value
                valueTranslation {
                    baseTranslation
                    languageCode
                    translationKey
                    group
                    tags
                    translations {
                        languageCode
                        translation
                    }
                }
                config
                defaultValue
                visible
            }
            categories {
                id
                description
                name
            }
            categoryIDs
            stock {
                allowNegative
                amount
                warnAt
            }
            images {
                id
                path
                tag
                width
                height
            }
            description
            descriptionTranslation {
                baseTranslation
                languageCode
                translationKey
                group
                tags
                translations {
                    languageCode
                    translation
                }
            }
            priceTableID
            priceTable {
                id
                name
                scales {
                    id
                    quantity
                    basePrice
                }
                options {
                    id
                    fieldName
                    fieldValue
                    weight
                    optional
                    hidden
                }
                points {
                    optionID
                    scaleID
                    price
                }
            }
            quantityOptions {
                mode
                min
                max
                scales
                stepSize
            }
        }
    }
    ${VARIANTS_FRAGMENT}
`;
