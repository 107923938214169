import React, { PropsWithChildren, useMemo } from 'react';

export interface ISlateButton extends PropsWithChildren {
    isActive: boolean;
    onClick: () => void;
}

export const SlateButton: React.FC<ISlateButton> = (props) => {
    const { onClick, isActive, children } = props;

    const activeStyles = useMemo(() => {
        return isActive ? 'bg-primary text-white' : 'bg-neutral-200 text-neutral-400';
    }, [isActive]);

    return (
        <>
            <button
                className={`flex h-8 w-min px-2 min-w-8 items-center justify-center ${activeStyles}`}
                onClick={onClick}
            >
                {children}
            </button>
        </>
    );
};
