/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { useThemeStyles } from '../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import React from 'react';

export const TitleStyleElement = ({ attributes, children, element }) => {
    const { applyStyles } = useThemeStyles();

    switch (element.type) {
        case 'heading-one':
            return (
                <h1
                    {...attributes}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.primary.DEFAULT',
                        }),
                    }}
                >
                    {children}
                </h1>
            );

        case 'heading-two':
            return (
                <h2
                    {...attributes}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.primary.DEFAULT',
                        }),
                    }}
                >
                    {children}
                </h2>
            );
        case 'list-item':
            return (
                <li
                    {...attributes}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.text.DEFAULT',
                        }),
                    }}
                >
                    {children}
                </li>
            );

        default:
            // paragraph
            return (
                <p
                    {...attributes}
                    // className=""
                    style={{
                        ...applyStyles({
                            textColor: 'colors.text.DEFAULT',
                        }),
                    }}
                >
                    {children}
                </p>
            );
    }
};
