import React, { useCallback } from 'react';
import { Button } from '../../controls/button/Button';
import { useTranslationSaveProductFieldLabel } from '../adapters/useTranslationSaveProductFieldLabel';
import { useTranslation } from '../hooks/useTranslation';

export const TranslationSaveButtonProductFieldLabel: React.FC = () => {
    const { saveProductFieldLabelTranslation, isPending } = useTranslationSaveProductFieldLabel();
    const { getT } = useTranslation();

    const handleSaveToDb = useCallback(() => {
        void saveProductFieldLabelTranslation();
    }, [saveProductFieldLabelTranslation]);

    return (
        <Button
            onClick={handleSaveToDb}
            color="success"
            disabled={isPending}
            enforceWhenLocationsControls={true}
            iconSpin={isPending}
            isTranslatable={false}
        >
            {getT('save') ?? 'Speichern'}
        </Button>
    );
};
